import React, { useMemo } from 'react';

import ProductionListing from 'components/Productions/Common/ProductionListing';
import Content from 'components/Globals/Layout/StubPage/Content';

import { getBaseSeasonFilters } from 'utils/productions';
import useAppContext from 'utils/hooks/useAppContext';
import { createDate } from 'utils/date';
import { DATE_FORMATS, SORT_OPTION_VALUES } from 'constants/index';

// NOTE: will removed this once new production listing is integrated everywhere
export const useSeasonFilters = ({ isUpcoming = false } = {}) => {
  const { obRouteContext } = useAppContext();
  const { page, filterParams } = obRouteContext;
  const { entity, entityType } = page || {};
  const baseFilters = useMemo(
    () =>
      getBaseSeasonFilters({
        entityType,
        entity,
        dateTo: filterParams?.date_to,
        dateFrom: isUpcoming ? createDate().format(DATE_FORMATS.FULL_DATE) : filterParams?.date_from,
        sort: isUpcoming ? SORT_OPTION_VALUES.YEAR_ASC : SORT_OPTION_VALUES.YEAR_DESC,
      }),
    [entityType, entity, filterParams?.date_to, filterParams?.date_from, isUpcoming],
  );

  return {
    ...filterParams,
    ...baseFilters,
  };
};

export const Seasons = () => {
  const queryFilters = useSeasonFilters();
  return <ProductionListing filters={queryFilters} hideActions hideFilters />;
};

const SeasonsWrapper = ({ entity, entityType, isOverview }) => {
  const sections = useMemo(() => {
    const components = [
      {
        component: Seasons,
        props: {
          isOverview,
        },
        showSection: entity?.stats?.productions?.exists,
      },
    ];

    return components;
  }, [entity, isOverview]);

  return <Content entity={entity} entityType={entityType} sections={sections} />;
};

export default SeasonsWrapper;
