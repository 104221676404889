import React, { useMemo } from 'react';
import classnames from 'classnames';

import { isWeekend, getDaysInMonth, getHighlightedDatesForMonth } from 'utils/calendar';
import { createDate } from 'utils/date';
import { CALENDAR_VIEW_TYPES } from 'constants/calendar';

import classes from './MonthCell.module.scss';

const DayCircle = ({ isFuture, isWeekendDay, isPast }) => (
  <div
    className={classnames(classes.dayCircle, {
      [classes.dayCircleWeekEnd]: isWeekendDay,
      [classes.dayCircleFuture]: isFuture,
      [classes.dayCirclePast]: isPast,
    })}
  ></div>
);

const DayBar = ({ isFuture, isWeekendDay, isPast }) => (
  <div
    className={classnames(classes.dayBar, {
      [classes.dayBarWeekEnd]: isWeekendDay,
      [classes.dayBarFuture]: isFuture,
      [classes.dayBarPast]: isPast,
    })}
  ></div>
);

const MonthCell = ({ year, month, performedDates, productionId, viewType }) => {
  const totalDays = useMemo(() => getDaysInMonth(year, month + 1), [month, year]);
  const days = Array.from({ length: totalDays }, (_, i) => i + 1);
  const highlightedDays = useMemo(() => getHighlightedDatesForMonth(performedDates, year, month), [
    month,
    performedDates,
    year,
  ]);

  return (
    <div
      className={classnames(classes.month, {
        [classes.monthsBar]: viewType === CALENDAR_VIEW_TYPES.QUARTER,
      })}
    >
      {days?.map(day => {
        const date = createDate(`${year}-${month}-${day}`);
        const isWeekendDay = isWeekend(date);
        const highlightedDay = highlightedDays.find(d => d.day === day);
        const isHighlighted = !!highlightedDay;
        const isPast = highlightedDay?.isPast || false;

        if (viewType === CALENDAR_VIEW_TYPES.QUARTER) {
          const hideBar = createDate(performedDates[performedDates.length - 1]?.startDate).isBefore(date);

          if (hideBar) {
            return null;
          }

          return (
            <DayBar
              key={`${productionId}-${year}-${month}-${day}`}
              isFuture={isHighlighted}
              isWeekendDay={isWeekendDay}
              isPast={isPast}
            />
          );
        }

        return (
          <DayCircle
            key={`${productionId}-${year}-${month}-${day}`}
            isFuture={isHighlighted}
            isWeekendDay={isWeekendDay}
            isPast={isPast}
          />
        );
      })}
    </div>
  );
};

export default MonthCell;
