/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useCallback } from 'react';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import EntityName from 'components/Globals/EntityName';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import WorkTypeTag from 'components/Work/Display/WorkTypeTag';

import useTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';

import { useTranslation } from 'src/i18n';
import { createDate } from 'utils/date';
import { trackClickTickets } from 'utils/tracking';
import { getProductionCredits, getProductionWorkTypeTag } from 'utils/productions';

import { TP } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';
import classes from './CalendarSlug.module.scss';

const WorkTag = ({ production }) => {
  const workTypeTag = getProductionWorkTypeTag(production);

  return <WorkTypeTag tag={workTypeTag} className={classes.workTag} />;
};

const ProductionName = ({ production, trackingData }) => {
  const { name, productionWorks } = production || {};
  const work = productionWorks?.[0];

  return (
    <div className={classes.productionName}>
      {name && (
        <Typography className={classes.productionName__name} size={12} weight="medium">
          {name}
        </Typography>
      )}
      {!name && (
        <Typography size={12} className={classes.productionName__workName} key={work?.work?.id}>
          <EntityName entityType={ENTITY_TYPES.WORK} entity={work?.work} trackingData={trackingData} withComposers />
        </Typography>
      )}
    </div>
  );
};

const TicketAction = ({ production }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();

  const { maxDate, tickets } = production || {};
  const ticketWebsite = tickets?.[0]?.website;
  const hasTickets =
    ticketWebsite &&
    (createDate().isSame(maxDate, 'day') || createDate(production?.maxDate)?.isAfter(createDate(), 'day'));

  const trackTickets = useCallback(() => {
    track.click(
      trackClickTickets({ 'Click URL': ticketWebsite, production_id: production?.id }),
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  }, [track, ticketWebsite, production]);

  if (!hasTickets) {
    return null;
  }

  if (production?.isCancelled) {
    return (
      <Typography size={12} color="secondary" className={classes.viewMore}>
        {t(`${TP}.FN_VIEW_MORE`)}
      </Typography>
    );
  }

  return (
    <LinkButton
      href={ticketWebsite}
      variant="text"
      onClick={trackTickets}
      styles={{ root: classes.ticketsBtn }}
      rightIcon={<SpriteIcon icon="open_in_new" size={12} className={classes.ticketsBtnIcon} />}
    >
      {t(`${TP}.FN_TICKETS`)}
    </LinkButton>
  );
};

const Location = ({ venues, onExpand, icon }) => {
  const venue = venues?.[0];

  return (
    <div className={classes.locationSingle}>
      <span className={classes.expandIcon}>
        {venues?.length > 1 && <SpriteIcon icon={icon} size={14} onClick={onExpand} />}
      </span>
      <div className={classes.venues}>
        <span className={classes.venues__location}>
          <Typography size={12} weight="medium">
            {venue?.country?.name}
            {', '}
          </Typography>
          <Typography size={12} color="secondary">
            {venue?.city?.name}
          </Typography>
        </span>
      </div>
    </div>
  );
};

const ProducerName = ({ producer, trackingData }) => (
  <Typography size={12} color="secondary">
    <EntityName
      entityType={producer?.entityType}
      entity={{ id: producer?.entity?.id, name: producer?.entity?.name }}
      trackingData={trackingData}
      isRaw
    />
  </Typography>
);

const CalendarSlug = ({
  production,
  entity,
  entityType,
  trackingData,
  openQuickPreview,
  handleExpandSlug,
  isExpanded,
}) => {
  const productionCredits = useMemo(
    () =>
      getProductionCredits(production, {
        entityId: entity?.id,
        entityType,
      }),
    [entity?.id, entityType, production],
  );

  const { directors, conductors, producers } = productionCredits || {};
  const producer = producers?.data?.[0];

  const handlePerformanceClick = e => {
    e.stopPropagation();
    openQuickPreview(e, { productionId: production?.id, ...production });
  };

  const icon = useMemo(() => {
    if (isExpanded) {
      return 'keyboard_arrow_up';
    }
    return 'expand_more';
  }, [isExpanded]);

  return (
    <div className={classes.calendarSlug} onClick={handlePerformanceClick}>
      <div className={classes.divider} />
      <div className={classes.calendarSlug__leftBlock}>
        <div className={classes.calendarSlug__leftBlock_genre}>
          <WorkTag production={production} className={classes.workTag} />
        </div>
        <div className={classes.calendarSlug__leftBlock_prodName}>
          <ProductionName production={production} />
          <div className={classes.tabletProducer}>
            <ProducerName producer={producer} trackingData={trackingData} />
          </div>
        </div>
        <div className={classes.calendarSlug__leftBlock_producer}>
          <ProducerName producer={producer} trackingData={trackingData} />
        </div>
        <div className={classes.calendarSlug__leftBlock_conductorDirector}>
          <>
            {directors?.data?.[0]?.entity?.id && (
              <div className={classes.directors}>
                <Typography size="12" color="secondary" className={classes.title}>
                  D:
                </Typography>
                <Typography size={12} color="secondary" truncate className={classes.name}>
                  <EntityName
                    key={directors?.data?.[0]?.entity?.id}
                    entityType={ENTITY_TYPES.PROFILE}
                    entity={directors?.data?.[0]?.entity}
                    trackingData={trackingData}
                  />
                </Typography>
              </div>
            )}
            {conductors?.data?.[0]?.entity?.id && (
              <div className={classes.conductors}>
                <Typography size="12" color="secondary" className={classes.title}>
                  C:
                </Typography>
                <Typography size={12} color="secondary" truncate className={classes.name}>
                  <EntityName
                    key={conductors?.data?.[0]?.entity?.id}
                    entityType={ENTITY_TYPES.PROFILE}
                    entity={conductors?.data?.[0]?.entity}
                    trackingData={trackingData}
                  />
                </Typography>
              </div>
            )}
          </>
        </div>
        <div className={classes.calendarSlug__leftBlock_countryCity}>
          <Location venues={production?.venues} icon={icon} onExpand={handleExpandSlug} />
          <div className={classes.tabletTicketAction}>
            <TicketAction production={production} />
          </div>
        </div>
        <div className={classes.calendarSlug__leftBlock_ticket}>
          <TicketAction production={production} />
        </div>
      </div>
    </div>
  );
};

export default CalendarSlug;
