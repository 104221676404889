import { useMemo } from 'react';

import { useSeasonFilters } from 'components/Productions/Display/Seasons';
import { useOrgIndexPageFiltersMeta } from 'components/Organization/Display/IndexPageFilters';

import productionQueries from 'containers/Productions/queries';
import artistQueries from 'containers/Artist/queries';

import getLinkProps from 'utils/globals/getLinkProps';

import usePageContext from 'utils/hooks/usePageContext';
import { getArtistIndexQueryParams, getSelectedProfession } from 'utils/artists';
import { useInfiniteQuery, useQuery } from 'utils/react-query';
import { useTranslation } from 'src/i18n';

import {
  TP,
  JSON_LD_TYPES,
  ENTITY_DETAILS_TAB,
  SEASON_TYPES,
  ENTITY_META_DETA_TYPES,
} from 'constants/index';
import { ROUTE_RESERVED_KEYWORDS, ENTITY_TYPES } from 'operabase-router/constants';

import useAppContext from 'utils/hooks/useAppContext';

import ArtistSEO from './artist';
import OrganisationSEO from './organisation';
import ProductionSEO from './production';
import VideoSEO from './video';
import WorkSEO from './work';

const ENTITY_SEO_MAP = {
  [ENTITY_TYPES.PROFILE]: ArtistSEO,
  [ENTITY_TYPES.ORGANIZATION]: OrganisationSEO,
  [ENTITY_TYPES.PRODUCTION]: ProductionSEO,
  [ENTITY_TYPES.WORK]: WorkSEO,
};

const getBreadcrumbJsonLd = ({ t, data }) => {
  const breadcrumbs = data?.reduce((acc, link) => {
    const path = link?.path || link?.as;

    if (path && path !== '/') {
      let linkUrl = link?.url || link?.path?.url;

      if (!linkUrl && typeof link?.path === 'string') {
        linkUrl = getLinkProps({ path: link?.path })?.url;
      }
      const linkName = t(link?.title) || link?.title;

      acc.push({
        position: acc.length + 1,
        name: linkName?.replace(/"/g, "'")?.trim(),
        item: encodeURI(linkUrl),
      });
    }

    return acc;
  }, []);

  return {
    itemListElements: breadcrumbs,
  };
};

export const useJsonLd = ({ type, entityType, entity, data }) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const { navigate } = usePageContext();

  const jsonLD = useMemo(() => {
    switch (type) {
      case JSON_LD_TYPES.BREADCRUMB: {
        return getBreadcrumbJsonLd({ t, data });
      }
      case JSON_LD_TYPES.LOCAL_BUSINESS: {
        if (entityType === ENTITY_TYPES.ORGANIZATION) {
          return OrganisationSEO.getLocalBusinessSEO({ entity });
        }

        return {};
      }
      case JSON_LD_TYPES.WEBPAGE: {
        return ProductionSEO.getWebpageSEO({ t, entity });
      }
      case JSON_LD_TYPES.EVENT: {
        return ProductionSEO.getEventJsonLd({ t, entity });
      }
      case JSON_LD_TYPES.VIDEO: {
        return VideoSEO.getVideoSEO({ entity });
      }
      case JSON_LD_TYPES.VOD: {
        return VideoSEO.getVodVideoSEO({ t, entity });
      }
      case JSON_LD_TYPES.LIVESTREAM: {
        return VideoSEO.getLsVideoSEO({ t, entity });
      }
      case JSON_LD_TYPES.SOCIAL_PROFILE: {
        if (entityType === ENTITY_TYPES.PROFILE) {
          const { url } = navigate.getLinkProps({ entity, entityType });
          return ArtistSEO.getSocialProfileSEO({ entity, url });
        }

        return {};
      }
      default: {
        return {};
      }
    }
  }, [entity, data, entityType, type, t, navigate]);

  return jsonLD;
};

export const getOgImages = ({ entityType, entity }) => {
  if ([ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION, ENTITY_TYPES.PRODUCTION].includes(entityType)) {
    return ENTITY_SEO_MAP[entityType]?.getOgImages({ entity });
  }

  return [];
};

export const useEntitySEO = ({ entityType, entity, activeTab }) => {
  const enabled = !!entity;
  const { filterParams, context, subPath } = usePageContext();
  const { t } = useTranslation('NS_APP_GLOBALS');

  const seasonType = useMemo(() => {
    let type = SEASON_TYPES.CALENDAR_YEAR;

    if (entityType === ENTITY_TYPES.ORGANIZATION) {
      type =
        entity?.metadata?.find(({ name }) => name === ENTITY_META_DETA_TYPES.SEASON)?.value ||
        SEASON_TYPES.CALENDAR_YEAR;
    }

    return type;
  }, [entity, entityType]);
  const seasonId = filterParams?.season_id?.[0];
  const baseFilters = useSeasonFilters();

  const { data } = useInfiniteQuery(
    productionQueries.getProductions({
      entityType,
      entityId: entity?.id,
      filters: baseFilters,
      limit: 10,
      queryConfig: {
        enabled:
          enabled &&
          (entityType === ENTITY_TYPES.ORGANIZATION ||
            (entityType === ENTITY_TYPES.PROFILE && subPath === ENTITY_DETAILS_TAB.PAST)),
      },
    }),
  );
  const { data: seasonList } = useQuery(
    productionQueries.getSeasons({
      filters: { ...baseFilters, aggregation_season_type: seasonType },
      queryConfig: { enabled: enabled && entityType !== ENTITY_TYPES.PRODUCTION && !!seasonId },
    }),
  );

  const productions = entityType === ENTITY_TYPES.PRODUCTION ? entity : data?.pages?.[0];
  const performanceCount = seasonList?.filter(({ id }) => id === parseInt(seasonId, 0))?.[0]?.perfCount || 0;

  if (!enabled) {
    return null;
  }

  const title =
    ENTITY_SEO_MAP[entityType]?.getSEOTitle({
      t,
      entity,
      entityType,
      activeTab,
      subPath,
      seasonId,
      performanceCount,
      productions,
      context,
    }) || '';
  const description =
    ENTITY_SEO_MAP[entityType]?.getSEODescription({
      t,
      entity,
      activeTab,
      subPath,
      productions,
      seasonId,
      context,
    }) || '';
  const ogImages = getOgImages({ entityType, entity });

  return {
    title,
    description,
    ogTitle: title,
    ...(ogImages?.length > 0 && { ogImages }),
  };
};

export const useArtistIndexSEO = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { obRouteContext } = useAppContext();
  const { basePath, baseRoute, linkProps, filterParams } = obRouteContext;
  const { filters } = linkProps;

  const { data } = useQuery(
    artistQueries.getArtistProfessions({
      queryConfig: {
        enabled: basePath === ROUTE_RESERVED_KEYWORDS.artists,
        select: response => response?.data,
      },
    }),
  );

  const { professions = [], voiceTypes = [], instrumentTypes = [] } = data || {};
  const apiFilters = getArtistIndexQueryParams({
    filterParams,
    filters,
    professions,
    voiceTypes,
    instrumentTypes,
  });
  const professionInfo = getSelectedProfession({ filters, professions, voiceTypes, instrumentTypes });

  const { data: professionsList } = useQuery(
    artistQueries.getArtists({
      filters: apiFilters,
      limit: 30,
      queryConfig: { enabled: !!filters?.page },
    }),
  );

  if (!baseRoute) {
    return {
      title: t('operabase.general.SEO_TITLE_HOMEPAGE'),
      description: t('operabase.general.SEO_DESC_HOMEPAGE'),
      ogTitle: t('operabase.general.SEO_OG_TITLE_HOMEPAGE'),
    };
  }

  if (baseRoute === ROUTE_RESERVED_KEYWORDS.ARTISTS) {
    if (filters?.profession?.length === 1 && !filters?.query) {
      const title = t('operabase.general.FN_ARTIST_INDEX_PROFESSION_TITLE', { profession: professionInfo?.label });
      return {
        title,
        description: t('operabase.general.FN_ARTIST_INDEX_PROFESSION_DESC', { profession: professionInfo?.label }),
        ogTitle: title,
      };
    }

    if (filters?.page && !filters?.letter) {
      const endPage = Math.ceil(professionsList?.total / professionsList?.limit);
      const title = t('operabase.general.FN_ARTIST_INDEX_PAGE_FILTER_TITLE', { page: filters?.page });
      return {
        title,
        description: t('operabase.general.FN_ARTIST_INDEX_PAGE_FILTER_DESC', {
          startPage: filters?.page,
          endPage,
          total: professionsList?.total,
        }),
        ogTitle: title,
      };
    }

    if (filters?.letter) {
      const endPage = Math.ceil(professionsList?.total / professionsList?.limit);
      const letter = filters?.letter.toUpperCase();
      const title = t('operabase.general.FN_ARTIST_INDEX_LETTER_FILTER_TITLE', {
        page: filters?.page,
        letter,
      });

      return {
        title,
        description: t('operabase.general.FN_ARTIST_INDEX_LETTER_FILTER_DESC', {
          startPage: filters?.page,
          endPage,
          total: professionsList?.total,
          letter,
        }),
        ogTitle: title,
      };
    }

    return {
      title: t('operabase.general.FN_ARTIST_INDEX_TITLE'),
      description: t('operabase.general.FN_ARTIST_INDEX_DESC'),
      ogTitle: t('operabase.general.FN_ARTIST_INDEX_TITLE'),
    };
  }

  return {
    title: t(`${TP}.m_OPERABASE`),
    description: t(`${TP}.m_OPERABASE`),
  };
};

export const useOrganizationIndexSEO = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const {
    hasAppliedFilters,
    hasAppliedOrgTypeFilter,
    hasAppliedCountryFilter,
    hasAppliedCityFilter,
    noIndexPage,
    selectedOrgType,
    selectedCountry,
    selectedCity,
  } = useOrgIndexPageFiltersMeta();

  const hasLocationFilter = hasAppliedCountryFilter || hasAppliedCityFilter;

  const other = {
    noindex: true,
    nofollow: true,
  };

  if (hasAppliedFilters) {
    if (hasLocationFilter) {
      let location = hasAppliedCountryFilter ? selectedCountry?.name : '';

      if (hasAppliedCityFilter) {
        if (location) {
          location += ', ';
        }

        location += selectedCity?.name;
      }

      if (hasAppliedOrgTypeFilter) {
        const title = [
          t(`${TP}.FN_ORG_TYPE_IN_LOCATION`, { orgType: selectedOrgType?.name, location }),
          t(`${TP}.FN_SEO_ARTS_ORGANISATIONS_TITLE_2`),
        ].join(' | ');

        return {
          title,
          description: t(`${TP}.FN_SEO_ORG_TYPE_LOCATION_DESC`, { orgType: selectedOrgType?.name, location }),
          ogTitle: title,
          ...(noIndexPage && { other }),
        };
      }

      const title = [
        t(`${TP}.FN_ORG_TYPE_IN_LOCATION`, { orgType: t(`${TP}.FN_ARTS_ORGANISATIONS`), location }),
        t(`${TP}.m_OPERABASE`),
      ].join(' | ');

      return {
        title,
        description: t(`${TP}.FN_SEO_ORG_INDEX_LOCATION_DESC`, { location }),
        ogTitle: title,
        ...(noIndexPage && { other }),
      };
    }

    if (hasAppliedOrgTypeFilter) {
      const title = [selectedOrgType?.name, t(`${TP}.FN_SEO_ARTS_ORGANISATIONS_TITLE_2`)].join(' | ');

      return {
        title,
        description: t(`${TP}.FN_SEO_ARTS_ORGANISATIONS_ORG_TYPE_DESC`, { orgType: selectedOrgType?.name }),
        ogTitle: title,
        ...(noIndexPage && { other }),
      };
    }
  }

  const title = [t(`${TP}.FN_SEO_ARTS_ORGANISATIONS_TITLE`), t(`${TP}.m_OPERABASE`)].join(' | ');

  return {
    title,
    description: t(`${TP}.FN_SEO_ARTS_ORGANISATIONS_DESC`),
    ogTitle: title,
    ...(noIndexPage && { other }),
  };
};
