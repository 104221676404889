/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import omit from 'lodash/omit';
import { useMediaQuery } from '@mui/material';
import Overflow from 'rc-overflow';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
// NOTE: eslint-disable-next-line import/no-cycle
import LinkButton from 'components/uiLibrary/LinkButton';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import { useStickyContext } from 'components/Globals/Layout/StickyContainer';
import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import { TP } from 'constants/index';
import { ENTITY_TYPES, FILTER_TYPES, OPERATORS, REGULAR_EXPRESSIONS } from 'operabase-router/constants';
import { getFilterAsPerPagePath } from 'utils/globals/filters';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';
import useTracking from 'components/Globals/Analytics';
import useAppContext from 'utils/hooks/useAppContext';
import useGetFilterTypes from 'utils/hooks/useGetFilterTypes';
import classes from './AppliedFilters.module.scss';

const processGeneralFilter = ({ option, accItems }) => {
  const { id, slug, name, filterType, parentFilterType } = option;
  const idValue = Number.parseInt(id, 10) || id;
  accItems.push({
    id: idValue,
    slug,
    label: name || idValue,
    type: parentFilterType || filterType,
    filterType,
  });
};

const FilterChip = ({ filter, onClick }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const FILTER_TYPE_SUB_HEADER = useMemo(
    () => ({
      [FILTER_TYPES.ORGANIZATION_TYPE]: t(`${TP}.FN_ORG_TYPE`),
      [FILTER_TYPES.PRODUCER]: t(`${TP}.FN_PRODUCER`),
      [FILTER_TYPES.WORK_TYPE]: t(`${TP}.FN_GENRE_STAGING`),
      [FILTER_TYPES.COUNTRY]: t(`${TP}.FN_COUNTRY_CITY`),
      [FILTER_TYPES.CITY]: t(`${TP}.FN_COUNTRY_CITY`),
    }),
    [t],
  );

  const onClickHandler = useCallback(() => {
    if (onClick) {
      onClick(filter);
    }
  }, [onClick, filter]);

  return (
    <div className={classes.chipWrapper}>
      <div className={classes.chip} onClick={onClickHandler}>
        <div className={classes.chip__label}>
          <Typography size={12} weight="medium">
            {filter?.label}
          </Typography>
          <Typography size={12} color="secondary">
            {FILTER_TYPE_SUB_HEADER[filter?.type] || filter?.filterType}
          </Typography>
        </div>
        <div className={classes.chip__icon}>
          <SpriteIcon icon="close" />
        </div>
      </div>
    </div>
  );
};

const AppliedFilters = ({
  className,
  isLoading,
  clearExcludedTypes = [],
  isVisibleAlways = false,
  trackingData = {},
  styles = {},
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { obRouteContext } = useAppContext();
  const { linkProps, page } = obRouteContext || {};
  const { filters = {} } = linkProps || {};
  const { entity, entityType, applyFilters, resetFilters, navigate, subPath } = usePageContext();
  const track = useTracking();

  const hideAppliedFilters = useMediaQuery('(max-width: 800px)');
  const isSticky = useStickyContext();
  const { excludeFilterTypes } = useGetFilterTypes([
    ...clearExcludedTypes,
    FILTER_TYPES.TICKET,
    FILTER_TYPES.WATCH_ONLINE,
    FILTER_TYPES.BOOLEAN_SEARCH,
    FILTER_TYPES.SINCE_YEAR,
    FILTER_TYPES.PAGE,
    FILTER_TYPES.LETTER,
  ]);

  const relevantFilters = useMemo(() => {
    const validFilters = Object.values(FILTER_TYPES);
    if (!validFilters) {
      return [];
    }
    const filtersAsPerPath = getFilterAsPerPagePath({ filters, page }) || {};

    return Object.keys(filtersAsPerPath)?.reduce((acc, filter) => {
      if (excludeFilterTypes.includes(filter)) {
        return acc;
      }

      const appliedFilter = filters[filter] || [];
      const flattenedFilters = appliedFilter?.reduce((accItems, item) => {
        if ([FILTER_TYPES.ROLE, FILTER_TYPES.PRODUCER, FILTER_TYPES.ENSEMBLE].includes(filter)) {
          const { entity: subFilter, contributors } = item;
          if (FILTER_TYPES.PRODUCER === filter) {
            const negatedContributors =
              contributors?.filter(({ entity: contributor }) =>
                REGULAR_EXPRESSIONS.NEGATED_ENTITY_ID.test(contributor?.id),
              ) || [];
            if ((!contributors?.length || negatedContributors?.length) && subFilter?.id) {
              let label;

              if (subFilter?.slug?.startsWith(OPERATORS.FILTER_TYPE_NEGATED)) {
                label = `Excluding all ${subFilter?.name}`;
              } else if (negatedContributors?.length) {
                label = `All ${subFilter?.name} excluding (${negatedContributors?.length})`;
              } else {
                label = `All ${subFilter?.name}`;
              }
              accItems.push({
                id: subFilter?.id,
                slug: subFilter?.slug,
                label,
                type: filter,
                filterType: subFilter?.slug,
              });
              return accItems;
            }
          }
          contributors.forEach(({ entity: contributor }) => {
            const { id, name, slug } = contributor;
            processGeneralFilter({
              option: { id, slug, name, filterType: subFilter?.name, parentFilterType: filter },
              accItems,
            });
          });
        } else if ([FILTER_TYPES.COUNTRY, FILTER_TYPES.CITY].includes(filter)) {
          const isCityFilter = filter === FILTER_TYPES.CITY;
          const mainItem = isCityFilter ? item?.parents?.[0]?.entity : item.entity;
          const city = isCityFilter ? item?.entity : item?.city;
          const { id, name, slug } = mainItem || {};
          let nameValue = name;

          if (city?.id) {
            nameValue = `${name} - ${city.name}`;
          }

          processGeneralFilter({
            option: {
              id: city?.id || id,
              slug: city?.slug || slug,
              name: nameValue,
              filterType: filter,
            },
            accItems,
          });
        } else {
          const mainItem = item.entity;
          const format = item?.format;
          const { id, name, slug } = mainItem || {};
          let nameValue = name;

          if (format?.id) {
            nameValue = `${name} - ${format.name}`;
          }
          processGeneralFilter({
            option: {
              id: format?.id || id,
              slug: format?.slug || slug,
              name: nameValue,
              filterType: filter,
            },
            accItems,
          });
        }

        return accItems;
      }, []);

      return [...acc, ...flattenedFilters];
    }, []);
  }, [excludeFilterTypes, filters, page]);

  const onClickHandler = useCallback(
    filter => {
      const filterKey = filter?.type;
      const appliedFilters = filters?.[filterKey];

      const options = (() => {
        if ([FILTER_TYPES.ROLE, FILTER_TYPES.PRODUCER, FILTER_TYPES.ENSEMBLE].includes(filterKey)) {
          return (
            appliedFilters?.flatMap(({ entity: subFilter, contributors, entityType: subFilterEntityType }) => {
              if (subFilter?.slug === filter?.filterType?.toLowerCase()) {
                const filteredContributors = contributors?.filter(
                  ({ entity: contributor }) =>
                    !REGULAR_EXPRESSIONS.NEGATED_ENTITY_ID.test(contributor?.id) &&
                    contributor?.id !== filter?.id &&
                    contributor?.slug !== filter?.slug,
                );
                return filteredContributors?.length
                  ? [{ entity: subFilter, contributors: filteredContributors, entityType: subFilterEntityType }]
                  : []; // NOTE: Remove the filter if all the contributors are negated
              }
              return [{ entity: subFilter, contributors, entityType: subFilterEntityType }];
            }) || []
          );
        }
        // NOTE: FIlter type city is being appended in case if any city is applied from separate dropdown on UI
        if (FILTER_TYPES.CITY === filterKey) {
          return appliedFilters?.filter(({ entity: contributor }) => contributor?.id !== filter?.id) || [];
        }
        if (FILTER_TYPES.COUNTRY === filterKey) {
          return (
            appliedFilters?.flatMap(({ entity: contributor, city }) => {
              if (city?.id) {
                return city.id !== filter?.id && city.slug !== filter?.slug
                  ? [{ entity: contributor, format: city, entityType: ENTITY_TYPES.COUNTRY }]
                  : []; // NOTE: please keep the format key, if you plan to change then please in LinkPropsExtractor { city: matchedSubEntity?.entity },
              }
              return contributor?.id !== filter?.id && contributor?.slug !== filter?.slug
                ? [{ entity: contributor, entityType: ENTITY_TYPES.COUNTRY }]
                : [];
            }) || []
          );
        }
        if (FILTER_TYPES.WORK_TYPE === filterKey) {
          return (
            appliedFilters?.flatMap(({ entity: contributor, format }) => {
              if (format?.id) {
                return format.id !== filter?.id && format.slug !== filter?.slug
                  ? [{ entity: contributor, format, entityType: ENTITY_TYPES.WORK_TYPE }]
                  : [];
              }
              return contributor?.id !== filter?.id && contributor?.slug !== filter?.slug
                ? [{ entity: contributor, entityType: ENTITY_TYPES.WORK_TYPE }]
                : [];
            }) || []
          );
        }

        return (
          appliedFilters?.filter(
            ({ entity: contributor }) => contributor?.id !== filter?.id && contributor?.slug !== filter?.slug,
          ) || []
        );
      })();
      applyFilters({
        entity,
        entityType,
        subPath,
        filters: {
          ...omit(filters, filterKey),
          ...(options?.length > 0 && { [filterKey]: options }),
        },
      });
      track.click({
        subComponent: SUB_COMPONENTS.DELETE_CTA,
        ...trackingData,
        meta: {
          [filterKey]: filter?.id,
        },
      });
    },
    [entity, entityType, filters, applyFilters, subPath],
  );

  const onResetFilters = useCallback(
    e => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      resetFilters({ entityType, entity, subPath }, [FILTER_TYPES.PAGE, FILTER_TYPES.LETTER, FILTER_TYPES.QUERY]);

      navigate.scrollTo();
    },
    [navigate, resetFilters, entityType, entity, subPath],
  );

  if (relevantFilters?.length === 0 || isLoading || (hideAppliedFilters && !isVisibleAlways)) {
    return null;
  }

  return (
    <div className={classnames(classes.root, { [className]: !!className })}>
      <HorizontalScrollIndicators
        styles={{ leftIndicator: classes.scrollLeftIndicator, rightIndicator: classes.scrollRightIndicator }}
        triggerSize={0}
        isArrowsEnabled
      >
        <Overflow
          className={classnames(classes.appliedFilters, {
            [styles?.appliedFilters]: !!styles?.appliedFilters,
          })}
          data={relevantFilters}
          renderItem={item => <FilterChip key={item?.id} filter={item} onClick={onClickHandler} />}
          renderRest={items => (
            <div className={classes.chipWrapper}>
              <LinkButton variant="text" trackingData={trackingData}>
                {t(`${TP}.FN_PLUS_X_MORE_NO_BRACKET`, { number: items?.length })}
              </LinkButton>
            </div>
          )}
          maxCount={isSticky ? 'responsive' : null}
          suffix={
            relevantFilters?.length > 0 ? (
              <LinkButton
                leftIcon={<SpriteIcon icon="replay-bold" />}
                variant="text"
                shape="rectangle"
                onClick={() => onResetFilters()}
                styles={{
                  root: classes.clearSearchText,
                  icon: classes.clearIcon,
                }}
                disableUnderline
                trackingData={{ subComponent: SUB_COMPONENTS.CLEAR_CTA, ...trackingData }}
              >
                <Typography size={13} weight="medium">
                  {t(`${TP}.AS_FILTERS_CLEAR_ALL_BTN`)}
                </Typography>
              </LinkButton>
            ) : null
          }
        />
      </HorizontalScrollIndicators>
    </div>
  );
};

export default AppliedFilters;
