import React, { useMemo } from 'react';
import { useTranslation } from 'src/i18n';

import Typography from 'components/uiLibrary/Typography';
import EntityName from 'components/Globals/EntityName';
import SeparatorList from 'components/uiLibrary/SeparatorList';

import { getProductionTitles } from 'utils/productions';

import { TP } from 'constants/index';

import classes from '../ProductionSlugDesktop.module.scss';

const ComposersList = React.memo(({ composers, trackingData }) => (
  <SeparatorList
    data={composers?.map(props => (
      <EntityName key={props?.entity?.id} {...props} trackingData={trackingData} />
    ))}
  />
));

const WorkTitle = ({ title, entityType, entityId, trackingData, isRaw = true }) => (
  <Typography weight="bold" size={12} className={classes.workTitle}>
    <EntityName
      name={title}
      entityType={entityType}
      entity={{ id: entityId }}
      trackingData={trackingData}
      isRaw={isRaw}
    />
  </Typography>
);

const TitleWrapper = ({ children, isCancelled, isArchived }) => (
  <Typography variant="p" strikethrough={isCancelled} blur={isArchived}>
    {children}
  </Typography>
);

const MusicalWorks = ({ production, isCancelled, isArchived, trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  const titles = useMemo(() => getProductionTitles(production), [production]);

  if (!titles?.length) {
    return null;
  }

  const hasCustomTitle = titles[0]?.isCustomTitle;
  const hasMultipleWorks = !hasCustomTitle && titles.length > 1;

  /* NOTE: Render custom title version */
  if (hasCustomTitle) {
    return (
      <div className={classes.musicalWorks}>
        <TitleWrapper isCancelled={isCancelled} isArchived={isArchived}>
          <WorkTitle
            title={titles[0].title}
            entityType={titles[0].entityType}
            entityId={titles[0].entityId}
            trackingData={trackingData}
            isRaw={!titles[0].isCustomTitle}
          />
        </TitleWrapper>

        <Typography size={12} italic className={classes.composers}>
          <ComposersList composers={titles[0].composers} trackingData={trackingData} />
        </Typography>

        {production?.productionWorks?.length > 1 && (
          <Typography size={12} className={classes.programCount} color="secondary">
            {t(`${TP}.FN_PROGRAM_COUNT_WORKS`, { count: production.productionWorks.length })}
          </Typography>
        )}
      </div>
    );
  }

  /* NOTE: Render multiple works version */
  if (hasMultipleWorks) {
    return (
      <div className={classes.musicalWorks}>
        <div className={classes.multipleWorks}>
          {titles.map(({ title, translation, composers, entityType, entityId }, index) => (
            <div key={`work-${entityId || index}`} className={classes.workItem}>
              <TitleWrapper isCancelled={isCancelled} isArchived={isArchived}>
                <WorkTitle title={title} entityType={entityType} entityId={entityId} trackingData={trackingData} />
                {translation && (
                  <Typography size={12} color="secondary">
                    {' '}
                    ({translation})
                  </Typography>
                )}
                <Typography size={12} italic>
                  , <ComposersList composers={composers} trackingData={trackingData} />
                </Typography>
              </TitleWrapper>
            </div>
          ))}
        </div>
      </div>
    );
  }

  /* NOTE: Render single work version */
  return (
    <div className={classes.musicalWorks}>
      <TitleWrapper isCancelled={isCancelled} isArchived={isArchived}>
        <WorkTitle
          title={titles[0].title}
          entityType={titles[0].entityType}
          entityId={titles[0].entityId}
          trackingData={trackingData}
        />
        {titles[0].translation && (
          <Typography size={12} color="secondary">
            {' '}
            ({titles[0].translation})
          </Typography>
        )}
        <Typography size={12} italic>
          , <ComposersList composers={titles[0].composers} trackingData={trackingData} />
        </Typography>
      </TitleWrapper>
    </div>
  );
};

export default MusicalWorks;
