import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { ENTITY_TYPES } from 'operabase-router/constants';
import { getEntityAndTypeFromContributor } from 'utils/productions';
import { ORGANIZATION_TYPE_IDS } from 'constants/index';
import route from '../../constants/routes';
import { canDoProductionAction, getRouteWithCountryAndSlug, isOwner } from '../../utils/common';
import { createEntity, deleteEntity, updateOne, useBufferValueAction } from '../App/actions';
import { PRODUCTIONS } from '../../constants/endpointConstants';
import {
  MS_TO_DAY_DIVIDER,
  PRIVACY_ATTRIBUTE_IDS,
  PRODUCTION_ADDED_IDENTIFIERS_IDS,
  PRODUCTION_PARTNER_TYPE,
} from '../../constants/consts';

export const getCompanyRoute = company => getRouteWithCountryAndSlug(route.COMPANIES, company);

export const checkProductionAction = (permission, production, companyId) =>
  canDoProductionAction(permission, PRODUCTION_ADDED_IDENTIFIERS_IDS.COMPANY, companyId, production);

export const errorMessageConverter = error => error?.responseText || error?.responseText?.message || error?.message;

function usePublishProduction(productionId, params, callback, onError) {
  const dispatch = useDispatch();

  return useCallback(() => {
    if (productionId) {
      dispatch(
        createEntity({
          endpoint: `${PRODUCTIONS}/${productionId}/actions`,
          sagaRoutine: useBufferValueAction,
          params: {
            type: 'publish',
            privacyAttribute: {
              id: PRIVACY_ATTRIBUTE_IDS.PUBLIC,
            },
            ...params,
          },
          callback,
          onError,
          errorMessageConverter,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionId, params, callback]);
}

export function usePublishCompanyProduction(productionId, companyId, callback, onError) {
  const params = {
    addedBy: { id: PRODUCTION_ADDED_IDENTIFIERS_IDS.COMPANY },
    approvedBy: { id: PRODUCTION_ADDED_IDENTIFIERS_IDS.COMPANY },
    company: {
      id: companyId,
    },
  };
  return usePublishProduction(productionId, params, callback, onError);
}

export function usePublishFestivalProduction(productionId, festivalId, callback, onError) {
  const params = {
    addedBy: { id: PRODUCTION_ADDED_IDENTIFIERS_IDS.FESTIVAL },
    approvedBy: { id: PRODUCTION_ADDED_IDENTIFIERS_IDS.FESTIVAL },
    festival: {
      id: festivalId,
    },
  };
  return usePublishProduction(productionId, params, callback, onError);
}

export function usePublishArtistProduction(productionId, artistId, callback, onError) {
  const params = {
    addedBy: { id: PRODUCTION_ADDED_IDENTIFIERS_IDS.ARTIST },
    profile: {
      id: artistId,
    },
  };
  return usePublishProduction(productionId, params, callback, onError);
}

/**
 * Returns publish production hook by entity type
 * @param entityType
 * @returns {(function(*=, *=, *=, *=): function(): void)|null}
 */
export function usePublishProductionFactory(entityType) {
  switch (entityType) {
    case ENTITY_TYPES.ORGANIZATION:
      return usePublishOrganizationProduction;
    case ENTITY_TYPES.PROFILE:
      return usePublishArtistProduction;
    default:
      return null;
  }
}

export function useDeleteProduction(production, org, callback) {
  const dispatch = useDispatch();
  const updateProduction = useUpdateProduction();
  const isPublished = Boolean(production?.published_at);

  return useCallback(() => {
    if (production) {
      const isOwnerOg = isOwner(production, PRODUCTION_ADDED_IDENTIFIERS_IDS.ORGANIZATION, org?.id);
      if (!isPublished || isOwnerOg) {
        dispatch(
          deleteEntity({
            endpoint: PRODUCTIONS,
            id: production?.id,
            callback,
          }),
        );
      } else if (isPublished || !isOwnerOg) {
        updateProduction(production?.id, {
          contributions: production?.contributions?.filter(c => c?.organization?.id !== org?.id),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [production, org, callback]);
}

export function useUpdateProduction(callback) {
  const dispatch = useDispatch();
  return (productionId, options) => {
    dispatch(
      updateOne({
        endpoint: PRODUCTIONS,
        id: productionId,
        params: options,
        sagaRoutine: useBufferValueAction,
        callback,
      }),
    );
  };
}

export const DELETION_DAYS_LIMIT = 10;

export function useProductionDeleteDaysLimit(publishedDate) {
  return useMemo(() => {
    const today = +new Date();
    const publishDate = +new Date(publishedDate);
    const numOfDaysAfterPublishing = (today - publishDate) / MS_TO_DAY_DIVIDER;
    return numOfDaysAfterPublishing > DELETION_DAYS_LIMIT;
  }, [publishedDate]);
}

export function usePublishOrganizationProduction(productionId, companyId, callback, onError) {
  const params = {
    addedBy: { id: PRODUCTION_ADDED_IDENTIFIERS_IDS.ORGANIZATION },
    approvedBy: { id: PRODUCTION_ADDED_IDENTIFIERS_IDS.ORGANIZATION },
    organization: {
      id: companyId,
    },
  };
  return usePublishProduction(productionId, params, callback, onError);
}

export const getProducerDetails = contributions =>
  contributions?.reduce(
    (result, entity) => {
      const { entity: contributor, entityType } = getEntityAndTypeFromContributor(entity);
      switch (entity?.contributionType) {
        case PRODUCTION_PARTNER_TYPE.PRODUCER:
          return { ...result, producer: { ...contributor, entityType } };
        case PRODUCTION_PARTNER_TYPE.CO_PRODUCER:
          return { ...result, coProducer: [...result?.coProducer, { ...contributor, entityType }] };
        case PRODUCTION_PARTNER_TYPE.HOST:
          return { ...result, presentedBy: [...result?.presentedBy, entity] };
        default:
          if (entity?.id === PRODUCTION_PARTNER_TYPE.OTHER) {
            return { ...result, other: [...result?.other, { ...contributor, entityType }] };
          }

          return result;
      }
    },
    { producer: undefined, coProducer: [], presentedBy: [], other: [] },
  );

export const organizationSelector = (onlyVenues, excludeVenues) => response => {
  let featuredEntities = response?.data;
  if (onlyVenues) {
    featuredEntities = response?.data?.filter(
      org => org?.organization?.organizationType?.id === ORGANIZATION_TYPE_IDS.VENUE,
    );
  } else if (excludeVenues) {
    featuredEntities = response?.data?.filter(
      org => org?.organization?.organizationType?.id !== ORGANIZATION_TYPE_IDS.VENUE,
    );
  }
  return { data: featuredEntities };
};
