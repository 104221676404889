export const CALENDAR_VIEW_TYPES = {
  MONTH: 'month',
  YEAR: 'year',
  QUARTER: 'quarter',
  FIFTEEN_DAYS: 'fifteen_days',
};

export const CALENDAR_VIEW_DAYS = {
  [CALENDAR_VIEW_TYPES.MONTH]: 30,
  [CALENDAR_VIEW_TYPES.QUARTER]: 90,
  [CALENDAR_VIEW_TYPES.FIFTEEN_DAYS]: 15,
  [CALENDAR_VIEW_TYPES.YEAR]: 12, // NOTE: as in case of years, we are showing 12 months
};

export const MONTHS_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const MONTHS_LONG = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
