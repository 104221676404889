/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useMemo } from 'react';
import _isEmpty from 'lodash-es/isEmpty';
import _toLower from 'lodash-es/toLower';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import useTracking from 'components/Globals/Analytics';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester';
import { SECTIONS, COMPONENTS, GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';
import { useQuery } from 'utils/react-query';

import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

import queries from 'containers/Productions/queries';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Modal from 'components/uiLibrary/Modal';
import IconButton from 'components/uiLibrary/IconButton';
import Typography from 'components/uiLibrary/Typography';
import { withSnackbar } from 'components/SnackbarNotification';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';

import WhatsappIcon from 'public/svg/icons/whatsapp.svg';
import LogoImage from 'public/images/logo.png';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import { useTranslation } from 'src/i18n';
import { getMediaSource } from 'utils/media';
import { getCityCountry } from 'utils/globals';
import { createDate } from 'utils/date';
import { getComposerNamesFromCreators } from 'utils/composer';
import { getProducerDetails } from 'containers/Organizations/utils';
import { trackEvent, TRACK_EVENTS } from 'utils/tracking';
import { getEntityLatestUpcomingPerformance, getProductionDateParts } from 'utils/productions';

import { DATE_FORMATS, TP } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';

import classes from './ShareEntity.module.scss';

const shareModalTrackingData = {
  section: SECTIONS.SHARE_ENTITY_MODAL,
  component: COMPONENTS.SOCIAL_MEDIA_CTA,
};

const shareOptions = {
  EMAIL: {
    text: 'Email',
    icon: <SpriteIcon icon="email_outline" className={classes.icon} />,
    component: EmailShareButton,
  },
  WHATSAPP: {
    text: 'Whatsapp',
    icon: (
      <Image
        src={WhatsappIcon}
        height={24}
        width={24}
        className={classes.whatsappIcon}
        disableCloudinary
        disableNextImage
      />
    ),
    component: WhatsappShareButton,
  },
  LINKEDIN: {
    text: 'Linkedin',
    icon: <SpriteIcon icon="linkedin:hover" size={24} />,
    component: LinkedinShareButton,
  },
  FACEBOOK: {
    text: 'Facebook',
    icon: <SpriteIcon icon="facebook:hover" size={24} />,
    component: FacebookShareButton,
  },
  TWITTER: {
    text: 'Twitter',
    icon: <SpriteIcon icon="twitter:hover" size={24} />,
    component: TwitterShareButton,
  },
};

const TextBlock = ({ text }) => (
  <Typography variant="span" className={classes.socialText}>
    {text}
  </Typography>
);

const ShareButton = ({ text, onClick, icon }) => (
  <button className={classes.socialBtn} onClick={onClick}>
    {icon}
    <TextBlock text={text} />
  </button>
);

const ShareButtons = ({ shareUrl, onCopy, onClick }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <>
      <ShareButton
        onClick={onCopy}
        text={t(`${TP}.FN_COPY_LINK`)}
        icon={<SpriteIcon icon="copy" className={classes.icon} />}
      />
      {Object.keys(shareOptions)?.map((key, index) => {
        const details = shareOptions[key];
        const Component = details?.component;

        return (
          <Component key={`${index}_${key}`} url={shareUrl} className={classes.socialBtn} onClick={() => onClick(key)}>
            {details?.icon}
            <TextBlock text={details?.text} />
          </Component>
        );
      })}
    </>
  );
};

// TODO - we need to make common component which can be used across application - will pick this in FB-22584
const UpcomingPerformance = ({ entity, entityType }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  // NOTE: do not move this call at parent level, hence this call will start triggering on page load
  const { data: production } = useQuery(
    queries.getProductionDetails({
      id: entity?.stats?.meta?.upcomingProductionId,
      queryConfig: { enabled: !!entity?.stats?.meta?.upcomingProductionId && entityType !== ENTITY_TYPES.PRODUCTION },
    }),
  );

  const performance = useMemo(() => {
    // entity will have startDate only if it is performance so there is not need to call getEntityLatestUpcomingPerformance
    const upcomingPerformance = entity?.startDate
      ? entity
      : getEntityLatestUpcomingPerformance(
          ENTITY_TYPES.PRODUCTION === entityType ? entity : production,
          entity?.id,
          entityType,
        );

    if (!upcomingPerformance?.startDate) {
      return null;
    }

    const dt = createDate(upcomingPerformance?.startDate);
    const dateArr = dt.format(DATE_FORMATS.FULL_DATE_FROM_STRING)?.split('-');

    return {
      ...upcomingPerformance,
      nextPerformanceDate: ` ${dateArr?.[1]} ${dateArr?.[0]} ${dt.format(
        DATE_FORMATS.FULL_DAY,
      )} ${upcomingPerformance?.startTime || ''}`,
      nextPerformanceDay: dateArr?.[2] || '',
      cityCountry: (entity?.city?.name || entity?.country?.name) && getCityCountry(upcomingPerformance),
    };
  }, [production, entity, entityType]);

  if (!performance?.startDate) {
    return null;
  }

  return (
    <>
      <Typography size="12" weight="medium" className={classes.upcomingPerformance}>
        {t(`${TP}.FN_NEXT_PERFORMING_DATE`)}
      </Typography>
      <div className={classes.performanceInfo}>
        {performance?.nextPerformanceDay && (
          <Typography weight="medium">
            {performance.nextPerformanceDay}
            {performance?.nextPerformanceDate && <Typography>{performance.nextPerformanceDate}</Typography>}
          </Typography>
        )}
        {performance?.venue?.name && (
          <Typography>
            <SpriteIcon icon="location_on_black" />
            {performance.venue.name}
            {performance?.cityCountry && <Typography> - {performance.cityCountry}</Typography>}
          </Typography>
        )}
      </div>
    </>
  );
};

// TODO - we need to make common component which can be used across application - will pick this in FB-22584
const IndividualInfo = ({ entityType, entity }) => {
  if (entityType !== ENTITY_TYPES.PROFILE || !entity?.metaInfo?.subTitle) {
    return null;
  }

  return (
    <Typography size="16" color="secondary" className={classes.contributionType}>
      {entity?.metaInfo?.subTitle}
    </Typography>
  );
};

// TODO - we need to make common component which can be used across application - will pick this in FB-22584
const OrganizationInfo = ({ entityType, entity }) => {
  if (entityType !== ENTITY_TYPES.ORGANIZATION) {
    return null;
  }

  return (
    <Typography size="16" color="secondary" className={classes.contributionType}>
      {entity?.organizationType?.name}
      {(entity?.city?.name || entity?.country?.name) && ` | ${getCityCountry(entity)}`}
    </Typography>
  );
};

// TODO - we need to make common component which can be used across application - will pick this in FB-22584
const ProducerInfo = ({ entity, entityType }) => {
  const { producer, dateRange } = useMemo(() => {
    if (!entity || entityType !== ENTITY_TYPES.PRODUCTION) {
      return {};
    }
    return {
      producer: getProducerDetails(entity?.contributions)?.producer,
      dateRange: getProductionDateParts(entity)?.years,
    };
  }, [entity, entityType]);

  if (!producer?.id) {
    return null;
  }

  return (
    <Typography size="16" color="secondary" className={classes.contributionType}>
      {producer?.name}
      {(producer?.city?.name || producer?.country?.name) && ` | ${getCityCountry(producer)}`}
      {dateRange && ` (${dateRange})`}
    </Typography>
  );
};

// TODO - we need to make common component which can be used across application - will pick this in FB-22584
const MusicalWorkInfo = ({ entity }) => {
  if (!entity?.productionWorks?.length || entity?.name) {
    return null;
  }

  return (
    <>
      {entity?.productionWorks?.map(({ work }) => (
        <Typography key={work?.id} weight="bold" size="16" className={classes.musicalWork}>
          {`${work?.original_name || work?.name}, `}
          {work?.name && work?.original_name && work?.name !== work?.original_name && (
            <Typography color="secondary">{`${work?.name} `}</Typography>
          )}
          {work?.creators && (
            <Typography color="secondary" italic>
              {getComposerNamesFromCreators(work?.creators, true)}
            </Typography>
          )}
        </Typography>
      ))}
    </>
  );
};

const ShareEntity = ({
  className,
  snackbarShowMessage,
  url,
  renderIconButton = false,
  showLabelText = true,
  customLabelText,
  entity: customEntity,
  entityType: customEntityType,
  trackingData,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const trackingClasses = useTrackingClickStyles(trackingData);
  const pageContext = usePageContext();
  const entity = customEntity || pageContext?.entity;
  const entityType = customEntityType || pageContext?.entityType;
  const [showModal, setShowModal] = useState(false);
  const { asPath } = useRouter();
  const isBrowser = typeof window !== 'undefined';
  const { origin } = isBrowser && window.location;
  const shareUrl = url || `${origin}${asPath}`;
  const { isMobile } = useDeviceTypeLayouts();

  const imgProps = useMemo(
    () => ({
      transformations: entityType === ENTITY_TYPES.PROFILE ? TRANSFORMATIONS.PROFILE_IMAGE : TRANSFORMATIONS.ORG_LOGO,
      height: 148,
      width: 100,
      disableNextImage: true,
      useIntersectionObserver: true,
    }),
    [entityType],
  );

  const src = getMediaSource({
    id: entity?.id,
    file: { urls: entity?.image || entity?.logo || entity?.poster?.file?.urls },
  });

  const onCopy = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      navigator.clipboard.writeText(shareUrl);
      setShowModal(false);
      snackbarShowMessage(t(`${TP}.GS_COPIED`));
      track.click({ ...shareModalTrackingData, component: COMPONENTS.COPY_CTA, meta: { shareUrl } });
    },
    [shareUrl, snackbarShowMessage, t],
  );

  const handleShareEntity = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      setShowModal(true);
      if (!_isEmpty(trackingData)) {
        track.click(trackingData);
      }

      track.click(
        trackEvent({
          name: TRACK_EVENTS.SHARE_ENTITY,
          data: {
            entity_type: entityType,
          },
        }),
        GOOGLE_OLD_TRACKING_SERVICES,
      );
    },
    [entityType],
  );

  const ModalWrapper = () => (
    <Modal
      isOpen={showModal}
      onClose={e => {
        if (e) {
          e.stopPropagation();
          e.preventDefault();
        }
        setShowModal(false);
      }}
      title={t(`${TP}.FN_SHARE_THIS_PAGE`)}
      className={classes.shareEntity__modalWrapper}
      allowMobileDrawer
    >
      <div className={classes.shareEntity__modalBody} onClick={e => e.stopPropagation()}>
        <div className={classes.shareEntity__modalInfo}>
          {src && <Image useIntersectionObserver disableNextImage alt={entity?.name} src={src} {...imgProps} />}
          {!isMobile && (
            <div className={classes.logo}>
              <Image
                src={LogoImage}
                alt="Operabase Home"
                height={isMobile ? 12 : 16}
                width={isMobile ? 120 : 160}
                lazy={false}
                className={classes.logoImage}
                disableCloudinary
                disableNextImage
              />
            </div>
          )}
          <div className={classes.details}>
            {entity?.name && (
              <Typography weight="bold" size="24">
                {entity?.name}
              </Typography>
            )}

            <MusicalWorkInfo entity={entity} />
            <ProducerInfo entity={entity} entityType={entityType} />
            <OrganizationInfo entity={entity} entityType={entityType} />
            <IndividualInfo entity={entity} entityType={entityType} />
            <UpcomingPerformance entity={entity} entityType={entityType} />
            <Typography size="12" className={classes.locationUrl}>
              {shareUrl}
            </Typography>
          </div>
        </div>
        <div className={classes.shareEntity__modalActions}>
          <ShareButtons
            onCopy={onCopy}
            shareUrl={shareUrl}
            onClick={key => {
              track.click({ ...shareModalTrackingData, meta: { socialMedia: _toLower(key), shareUrl } });
            }}
          />
        </div>
      </div>
    </Modal>
  );

  if (renderIconButton) {
    return (
      <>
        <IconButton
          onClick={handleShareEntity}
          variant="secondary"
          className={classnames(classes.iconBtnSmall, trackingClasses, { [className]: !!className })}
        >
          <SpriteIcon icon="share" size={26} />
        </IconButton>
        <ModalWrapper />
      </>
    );
  }

  return (
    <div
      className={classnames(classes.shareEntity, trackingClasses, {
        [className]: !!className,
      })}
      onClick={handleShareEntity}
    >
      <span
        className={classnames(classes.shareEntity__label, { [classes.noLabel]: !showLabelText && !customLabelText })}
      >
        <SpriteIcon icon="share" size={14} className={classes.shareIcon} />
        {customLabelText && <Typography weight="medium">{customLabelText}</Typography>}
        {showLabelText && (
          <Typography weight="medium" size={14} className={classes.text}>
            {t(`${TP}.m_SOCIAL_SHARE`)}
          </Typography>
        )}
      </span>

      <ModalWrapper />
    </div>
  );
};

export default withSnackbar(ShareEntity);
