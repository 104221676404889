import React, { useMemo } from 'react';
import classnames from 'classnames';
import Typography from 'components/uiLibrary/Typography';

import { CALENDAR_VIEW_TYPES } from 'constants/calendar';
import { getDayHeaders } from 'utils/calendar';
import classes from './CalendarHeaders.module.scss';

const CalendarHeaders = ({ calendarViewType, startDate, months }) => {
  const days = useMemo(() => {
    if ([CALENDAR_VIEW_TYPES.MONTH, CALENDAR_VIEW_TYPES.FIFTEEN_DAYS].includes(calendarViewType)) {
      return getDayHeaders({
        startDate,
        daysToRender: calendarViewType === CALENDAR_VIEW_TYPES.MONTH ? 30 : 15,
      });
    }
    return [];
  }, [calendarViewType, startDate]);

  if (days?.length) {
    return days.map(({ day, weekday }) => (
      <th key={day} className={classes.dayHeader}>
        <Typography
          variant="div"
          size={calendarViewType === CALENDAR_VIEW_TYPES.FIFTEEN_DAYS ? 12 : 10}
          weight="medium"
          className={classes.dayHeader__day}
        >
          {day}
        </Typography>
        <Typography size={10}>{weekday}</Typography>
      </th>
    ));
  }

  return months?.map((month, index) => (
    <th
      key={index}
      className={classnames(classes.yearMonthsHeader, {
        [classes.quarterMonthHeader]: calendarViewType === CALENDAR_VIEW_TYPES.QUARTER,
      })}
    >
      {month}
    </th>
  ));
};

export default CalendarHeaders;
