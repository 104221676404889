/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useMemo, createContext, useContext } from 'react';

import CalendarSlug from 'components/Productions/Display/Slugs/CalendarSlug';
import FilterFacets, { FILTER_LAYOUT } from 'components/Filters/Display/FilterFacets';

import { getDateDifference, getViewRange } from 'utils/calendar';
import { createDate } from 'utils/date';
import { CALENDAR_VIEW_DAYS, CALENDAR_VIEW_TYPES } from 'constants/calendar';
import { TP } from 'constants/index';
import { FILTER_TYPES } from 'operabase-router/constants';

import useAppContext from 'utils/hooks/useAppContext';
import Cells from './Calendar/Cells';
import CalendarRangeNavigation from './Calendar/CalendarRangeNavigation';
import CalendarHeaders from './Calendar/CalendarHeaders';
import classes from './CalendarProductionListing.module.scss';

const customFilterGroups = {
  [FILTER_LAYOUT.BUTTON_GROUP_DETAILED]: [
    { name: `${TP}.FN_GENRE`, filterTypes: [FILTER_TYPES.CREATOR] },
    { name: `${TP}.m_PROD`, filterTypes: [FILTER_TYPES.WORK] },
    { name: `${TP}.FN_PRODUCER`, filterTypes: [FILTER_TYPES.PRODUCER] },
    { name: `${TP}.WHO`, filterTypes: [FILTER_TYPES.WHO] },
    { name: `${TP}.WHERE`, filterTypes: [FILTER_TYPES.WHERE] },
    { name: `${TP}.TICKET`, filterTypes: [FILTER_TYPES.TICKET] },
  ],
};

const CalendarViewContext = createContext();

const useGetCalendarRange = () => {
  const { obRouteContext } = useAppContext();
  const { filterParams = {} } = obRouteContext;
  const { date_from, date_to } = filterParams;
  const { calendarViewType, months, endDate: rangeEnd, startDate: rangeStart, dateFrom } = useMemo(() => {
    const dateTo = date_to ? createDate(date_to) : null;
    const fromDate = date_from ? createDate(date_from) : createDate();

    let viewType = CALENDAR_VIEW_TYPES.YEAR;
    if (dateTo) {
      const daysDifference = getDateDifference(fromDate, dateTo);
      if (daysDifference <= 15) {
        viewType = CALENDAR_VIEW_TYPES.FIFTEEN_DAYS;
      } else if (daysDifference <= 30) {
        viewType = CALENDAR_VIEW_TYPES.MONTH;
      } else if (daysDifference <= 90) {
        viewType = CALENDAR_VIEW_TYPES.QUARTER;
      }
    }

    return {
      calendarViewType: viewType,
      ...getViewRange(viewType, fromDate.year(), fromDate),
      dateFrom: fromDate,
    };
  }, [date_from, date_to]);

  return {
    year: dateFrom?.year(),
    startDate: dateFrom,
    months,
    rangeStart,
    rangeEnd,
    calendarViewType,
  };
};

export const CalendarViewSlug = ({ entityType, entity, data: production, openQuickPreview, trackingData }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { year, months, startDate, calendarViewType } = useContext(CalendarViewContext);

  const handleExpandSlug = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <tr className={classes.slugTable__row} key={production?.id}>
      <td>
        <CalendarSlug
          entity={entity}
          entityType={entityType}
          production={production}
          openQuickPreview={openQuickPreview}
          trackingData={trackingData}
          handleExpandSlug={handleExpandSlug}
        />

        {isExpanded && <div className={classes.calendarSlug__leftBlock}>Hola Expanded</div>}
      </td>
      <Cells
        production={production}
        calendarViewType={calendarViewType}
        startDate={startDate}
        year={year}
        months={months}
      />
    </tr>
  );
};

const CalendarProductionListing = ({ rows }) => {
  const {
    calendarViewType,
    year,
    months,
    startDate,
    rangeStart,
    rangeEnd,
    setYear,
    setStartDate,
  } = useGetCalendarRange();

  return (
    <CalendarViewContext.Provider value={{ year, months, startDate, rangeStart, rangeEnd, calendarViewType }}>
      <table className={classes.slugTable}>
        <thead className={classes.slugTable__head}>
          <tr>
            <td className={classes.slugTable__head_filters}>
              <FilterFacets
                layout={FILTER_LAYOUT.BUTTON_GROUP_DETAILED}
                customGroup={customFilterGroups}
                ignoreSmallScreenGroup
                styles={{
                  root: classes.filterContainerDetailed,
                  filterGroupBox: classes.filterGroupBox,
                }}
              />
            </td>
            <td colSpan={CALENDAR_VIEW_DAYS[calendarViewType]}>
              <CalendarRangeNavigation
                viewType={calendarViewType}
                year={calendarViewType === CALENDAR_VIEW_TYPES.YEAR ? year : undefined}
                startDate={calendarViewType !== CALENDAR_VIEW_TYPES.YEAR ? rangeStart : undefined}
                endDate={calendarViewType !== CALENDAR_VIEW_TYPES.YEAR ? rangeEnd : undefined}
                onYearChange={calendarViewType === CALENDAR_VIEW_TYPES.YEAR ? setYear : undefined}
                onDateChange={calendarViewType !== CALENDAR_VIEW_TYPES.YEAR ? setStartDate : undefined}
              />
            </td>
          </tr>
          <tr className={classes.slugTable__head_row}>
            <td />
            <CalendarHeaders calendarViewType={calendarViewType} startDate={startDate} months={months} />
          </tr>
        </thead>

        <tbody>{rows}</tbody>
      </table>
    </CalendarViewContext.Provider>
  );
};

export default CalendarProductionListing;
