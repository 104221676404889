import { DATE_FORMATS } from 'constants/index';
import { MONTHS_SHORT, MONTHS_LONG, CALENDAR_VIEW_TYPES, CALENDAR_VIEW_DAYS } from 'constants/calendar';
import { createDate } from './date';

export const isWeekend = date => {
  const day = date.day();
  return day === 0 || day === 6;
};

export const formatDateString = (year, month, day) => {
  const monthInStr = month + 1 < 10 ? `0${month + 1}` : String(month + 1);
  const dayInStr = day < 10 ? `0${day}` : String(day);
  return `${year}-${monthInStr}-${dayInStr}`;
};

export const getDateDifference = (startDate, endDate) => {
  const diffTime = Math.abs(endDate.valueOf() - startDate.valueOf());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const getDaysInMonth = (year, month) => createDate(`${year}-${month + 1}-0`).date();

export const getHighlightedDatesForMonth = (dates, year, month) =>
  dates
    .map(dateObj => {
      const date = createDate(dateObj.startDate);
      return {
        day: date.date(),
        isPast: date < createDate(),
        year: date.year(),
        month: date.month(),
      };
    })
    .filter(date => date.year === year && date.month === month);

export const getMonthRange = ({ startDate, days = CALENDAR_VIEW_DAYS[CALENDAR_VIEW_TYPES.QUARTER], variant }) => {
  const monthVariants = variant === 'short' ? MONTHS_SHORT : MONTHS_LONG;
  let endDate = startDate;
  endDate = endDate.date(startDate.date() + days - 1);

  const months = new Set();
  let currentDate = startDate;

  while (currentDate <= endDate) {
    const monthName = monthVariants[currentDate.month()];
    months.add(monthName);
    currentDate = currentDate.month(currentDate.month() + 1);
  }

  return {
    months: Array.from(months),
    startDate,
    endDate,
  };
};

export const getDayHeaders = ({ startDate, daysToRender = 30 }) => {
  const days = [];
  let currentDate = startDate;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < daysToRender; i++) {
    days.push({
      day: currentDate.date() < 10 ? String(currentDate.date()).padStart(2, '0') : String(currentDate.date()),
      weekday: currentDate.format(DATE_FORMATS.FULL_DAY).charAt(0),
    });
    currentDate = currentDate.date(currentDate.date() + 1);
  }
  return days;
};

export const getViewRange = (viewType, year, startDate) => {
  const days = CALENDAR_VIEW_DAYS[viewType];
  switch (viewType) {
    case CALENDAR_VIEW_TYPES.YEAR:
      return {
        months: MONTHS_SHORT,
        startDate: createDate(`${year}-0-1`),
        endDate: createDate(`${year}-11-31`),
      };
    case CALENDAR_VIEW_TYPES.MONTH:
      return getMonthRange({ startDate, days, variant: 'short' });
    case CALENDAR_VIEW_TYPES.FIFTEEN_DAYS:
      return getMonthRange({ startDate, days, variant: 'short' });
    default:
      return getMonthRange({ startDate, days });
  }
};
