/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback } from 'react';
import classnames from 'classnames';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import IconButton from 'components/uiLibrary/IconButton';
import Typography from 'components/uiLibrary/Typography';

import { useTranslation } from 'src/i18n';

import { CALENDAR_VIEW_TYPES } from 'constants/calendar';
import { DATE_FORMATS, TP } from 'constants/index';

import { createDate } from 'utils/date';
import classes from './CalendarRangeNavigation.module.scss';

const SHOW_TYPE = {
  PAST: {
    value: `${TP}.PAST_SHOWS`,
    name: 'past',
  },
  UPCOMING: {
    value: `${TP}.UPCOMING_SHOWS`,
    name: 'upcoming',
  },
  WEEKEND: {
    value: `${TP}.WEEKEND_SHOWS`,
    name: 'weekend',
  },
  CANCELLED: {
    value: `${TP}.CANCELLED_SHOWS`,
    name: 'cancelled',
  },
};

const CalendarRangeNavigation = ({ viewType, year, startDate, endDate, onYearChange, onDateChange }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const formatDate = date => date.format(DATE_FORMATS.USER_FRIENDLY_DATE);

  const moveRange = useCallback(
    amount => {
      if (!viewType || (!onYearChange && !onDateChange)) {
        return undefined;
      }

      const daysInView =
        {
          [CALENDAR_VIEW_TYPES.YEAR]: null,
          [CALENDAR_VIEW_TYPES.MONTH]: 30,
          [CALENDAR_VIEW_TYPES.FIFTEEN_DAYS]: 15,
        }[viewType] || 90;

      if (viewType === CALENDAR_VIEW_TYPES.YEAR && onYearChange && year) {
        onYearChange(year + amount);
      } else if (onDateChange && startDate) {
        const newDate = startDate.date(startDate.date() + amount * daysInView);
        onDateChange(newDate);
      }
      return true;
    },
    [onDateChange, onYearChange, startDate, viewType, year],
  );

  return (
    <div className={classes.calendarRangeNavigation}>
      <div className={classes.showTypes}>
        {Object.keys(SHOW_TYPE).map(type => (
          <div className={classes.showTypes__type}>
            <span
              className={classnames(classes.showTypes__type_box, {
                [classes[`box_${SHOW_TYPE[type]?.name}`]]: !![classes[`box_${SHOW_TYPE[type]?.name}`]],
              })}
            ></span>
            <Typography size={11} key={type.value} className={classes.showType}>
              {t(SHOW_TYPE[type]?.value)}
            </Typography>
          </div>
        ))}
      </div>
      <div className={classes.yearNavigation}>
        <IconButton className={classes.yearNavigation__previous} onClick={() => moveRange(-1)}>
          <SpriteIcon icon="chevron_left" />
        </IconButton>
        <Typography weight="medium" className={classes.yearNavigation__title}>
          {viewType === CALENDAR_VIEW_TYPES.YEAR ? year : `${formatDate(startDate)} - ${formatDate(endDate)}`}
        </Typography>
        <IconButton className={classes.yearNavigation__next} onClick={() => moveRange(1)}>
          <SpriteIcon icon="chevron_right" />
        </IconButton>
      </div>
    </div>
  );
};

export default CalendarRangeNavigation;
