import React, { useMemo } from 'react';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import { getEntityAndTypeFromContributor } from 'utils/productions';
import { ENTITY_TYPES } from 'operabase-router/constants';

import classes from './RedMaskIcon.module.scss';

const RedMaskIcon = ({ entity, entityType, data }) => {
  const { show, icon } = useMemo(() => {
    const showRedMask = data?.contributions?.find(contributor => {
      const { entity: contributorEntity } = getEntityAndTypeFromContributor(contributor);
      return contributorEntity?.id === entity?.id;
    })?.isRedMasked;

    if ([ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION].includes(entityType)) {
      return {
        show: true,
        icon: showRedMask ? 'red_mask' : 'grey_mask',
      };
    }

    return {
      show: false,
      icon: null,
    };
  }, [entityType, entity, data]);

  if (show) {
    return <SpriteIcon className={classes.redMaskIcon} icon={icon} size={14} />;
  }

  return null;
};

export default RedMaskIcon;
