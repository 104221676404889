/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'src/i18n';

import WorkTypeTag from 'components/Work/Display/WorkTypeTag';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import EntityName from 'components/Globals/EntityName';
import LinkButton from 'components/uiLibrary/LinkButton';
import RedMaskIcon from 'components/Productions/Display/RedMaskIcon';
import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import ImpressionTracker from 'utils/components/impressionTracker';
import { getProductionWorkTypeTag, getProductionTags, getProductionCredits } from 'utils/productions';
import { createDate, getFormattedDateRange } from 'utils/date';
import { TP, TAG_TYPES, DATE_FORMATS } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';
import Actions from './Actions';
import MusicalWorks from './MusicalWorks';
import PerformanceDates from './PerformanceDates';
import VenuesListing from './VenuesListing';

import classes from './ProductionSlugDesktop.module.scss';

const WorkTag = ({ production, className }) => {
  const workTypeTag = useMemo(() => getProductionWorkTypeTag(production), [production]);

  if (!workTypeTag) {
    return null;
  }

  return <WorkTypeTag tag={workTypeTag} className={className || classes.workTag} />;
};

const StageTag = ({ production }) => {
  const stagingType =
    production?.productionWorkTypes?.[0]?.stagingType ||
    production?.productionWorks?.[0]?.productionWorkToWorkTypes?.[0]?.stagingType;

  if (!stagingType?.name) {
    return null;
  }

  return (
    <Typography className={classes.stageTag} size={12} color="secondary">
      {stagingType?.name}
    </Typography>
  );
};

const DateRange = ({ production, isDayView }) => {
  const { minDate, maxDate, isCancelled, isArchived, dates } = production || {};
  const [weekday, day, month] = createDate(dates?.[0]?.date)
    .format(DATE_FORMATS.DATE_LABEL_WITH_DAY)
    .split(' ');
  const firstPerformanceTime = dates?.[0]?.time;

  const { rangeStart, rangeEnd, separator, fromISO, toISO } = useMemo(
    () =>
      getFormattedDateRange({
        from: minDate,
        to: maxDate || minDate,
        asParts: true,
        dateFormat: DATE_FORMATS.DAY_MONTH,
      }),
    [maxDate, minDate],
  );

  const startYear = createDate(fromISO).format('YYYY');
  const endYear = toISO ? createDate(toISO).format('YYYY') : null;

  return (
    <>
      {isDayView && (
        <div className={classes.firstPerformanceInfo}>
          <Typography size={12} weight="bold">
            {day}
          </Typography>
          <Typography size={12} color="secondary">
            {month}
          </Typography>
          <Typography size={12} color="secondary">
            {weekday}
          </Typography>
          {firstPerformanceTime && (
            <Typography size={12} color="secondary">
              {firstPerformanceTime}
            </Typography>
          )}
        </div>
      )}
      <Typography size={12} className={classes.dateRange}>
        <Typography
          size={12}
          weight={isDayView ? 'regular' : 'bold'}
          {...(isCancelled && { strikethrough: true })}
          {...(isArchived && { blur: true })}
          className={classes.rangeDates}
        >
          <time className="dt-start" dateTime={fromISO}>
            {rangeStart}
          </time>{' '}
          {separator?.trim()}{' '}
          {rangeEnd && (
            <time className="dt-end" dateTime={toISO}>
              {rangeEnd}
            </time>
          )}
        </Typography>
        <Typography
          className={classes.dateYear}
          size={12}
          {...(isCancelled && { strikethrough: true })}
          {...(isArchived && { blur: true })}
        >
          <time dateTime={fromISO}>{startYear}</time>
          {endYear !== startYear && (
            <>
              {endYear && ` - `}
              {endYear && <time dateTime={toISO}>{endYear}</time>}
            </>
          )}
        </Typography>
      </Typography>
    </>
  );
};

const ProductionTags = ({ production }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  const { otherTags } = useMemo(() => {
    const isRevival = production?.isRevival;
    let commonTags = [];

    if (isRevival) {
      commonTags = [
        ...commonTags,
        {
          type: TAG_TYPES.PRODUCTION,
          label: t(`${TP}.FN_REVIVAL`),
        },
      ];
    }

    if (production?.productionWorks?.length > 1) {
      commonTags = [...commonTags];
    }

    const tags = getProductionTags(
      {
        ...production,
        performances: production?.performances || production?.dates,
      },
      [TAG_TYPES.PRODUCTION, TAG_TYPES.FESTIVAL, TAG_TYPES.DATE],
    );

    return {
      otherTags: [...tags, ...commonTags]?.map(tag => {
        if (tag.skipWrapper) {
          return tag.label;
        }
        return (
          <Typography key={tag?.label} className={classes.otherTag} size={10} color="secondary">
            {t(tag?.label)}
          </Typography>
        );
      }),
    };
  }, [t, production]);

  return (
    <div className={classes.tagList}>
      <div className={classes.productionTags}>{otherTags}</div>
    </div>
  );
};

const Producer = ({ producer, isCancelled, isArchived, trackingData, maxDate, minDate }) => {
  const { permissions, navigate } = usePageContext();
  const { entityType, entity } = producer || {};
  const seasonPeriod = useMemo(
    () =>
      getFormattedDateRange({
        from: minDate,
        to: maxDate || minDate,
        showSeasonPeriod: true,
      }),
    [maxDate, minDate],
  );

  const producerLinkProps = useMemo(
    () =>
      navigate.getLinkProps({
        entityType,
        entity,
        pro: permissions?.castingToolPermissions?.hasAccess,
        onlyLinkProps: true,
        rel: 'nofollow',
      }),
    [navigate, entity, entityType, permissions?.castingToolPermissions?.hasAccess],
  );

  return (
    <>
      <LinkButton
        styles={{ root: classes.producerLink }}
        variant="native"
        {...producerLinkProps}
        target="_blank"
        isLink
        trackingData={{
          ...trackingData,
          entityId: entity?.id,
          entityName: entity?.name,
          entityType,
        }}
      >
        <Typography key={producer?.entity?.id} strikethrough={isCancelled} blur={isArchived} size={12}>
          <EntityName entity={producer?.entity} entityType={producer?.entityType} isRaw trackingData={trackingData} />
        </Typography>
        <Typography size={12} className={classes.seasonPeriod}>
          ({seasonPeriod})
        </Typography>
      </LinkButton>
    </>
  );
};

const ConductorDirector = ({ title, crew, isCancelled, isArchived, trackingData }) => {
  const { permissions, navigate } = usePageContext();
  const { t } = useTranslation('NS_APP_GLOBALS');

  if (!crew?.length) {
    return null;
  }

  const translatedKey = title === 'C:' ? t(`${TP}.m_CONDUCTOR`) : t(`${TP}.FN_DIRECTOR`);

  return (
    <div className={classes.cdBlock}>
      <meta itemProp="role" content={translatedKey} />
      <div className={classes.cdBlock__namesList}>
        {crew.map((member, index) => {
          const { entity } = member || {};

          if (!entity?.id) {
            return null;
          }

          const crewLinkProps = navigate.getLinkProps({
            entityType: ENTITY_TYPES.PROFILE,
            entity,
            pro: permissions?.castingToolPermissions?.hasAccess,
            onlyLinkProps: true,
            rel: 'nofollow',
          });

          return (
            <div key={entity.id} className={classes.cdBlock__nameItem}>
              {index === 0 && (
                <Typography size={12} className={classes.cdBlock__prefix}>
                  {title}
                </Typography>
              )}
              <LinkButton
                isLink
                variant="text"
                {...crewLinkProps}
                styles={{ root: classes.conductorDirectorName }}
                target="_blank"
                trackingData={{
                  ...trackingData,
                  entityId: entity?.id,
                  entityName: entity?.name,
                  entityType: ENTITY_TYPES.PROFILE,
                }}
              >
                <Typography strikethrough={isCancelled} blur={isArchived} size={12}>
                  <EntityName entityType={ENTITY_TYPES.PROFILE} entity={entity} isRaw trackingData={trackingData} />
                </Typography>
              </LinkButton>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CastInstrumentalist = ({
  cast,
  instrumentalists,
  isCancelled,
  isArchived,
  trackingData,
  production,
  entity: mainEntity,
}) => {
  const { permissions, navigate } = usePageContext();

  if (!cast?.length && !instrumentalists?.length) {
    return null;
  }

  /* NOTE: Use cast if available, otherwise use instrumentalists */
  const profiles = cast?.length > 0 ? cast?.[0]?.data : instrumentalists?.[0]?.data;

  if (!profiles?.length) {
    return null;
  }

  /* NOTE: Limit to 3 rows maximum */
  const displayProfiles = profiles.slice(0, 3);

  return (
    <div className={classes.castInstrumentalist}>
      <div className={classes.castInstrumentalist__namesList}>
        {displayProfiles.map((profile, index) => {
          const { entity, entityType } = profile || {};

          if (!entity?.id) {
            return null;
          }

          const profileLinkProps = navigate.getLinkProps({
            entityType: entityType || ENTITY_TYPES.PROFILE,
            entity,
            pro: permissions?.castingToolPermissions?.hasAccess,
            onlyLinkProps: true,
            rel: 'nofollow',
          });

          return (
            <div key={entity.id} className={classes.castInstrumentalist__nameItem}>
              <LinkButton
                isLink
                variant="text"
                {...profileLinkProps}
                styles={{ root: classes.castCrewName }}
                target="_blank"
                trackingData={{
                  ...trackingData,
                  entityId: entity?.id,
                  entityName: entity?.name,
                  entityType: entityType || ENTITY_TYPES.PROFILE,
                }}
              >
                <Typography strikethrough={isCancelled} blur={isArchived} size={12}>
                  {mainEntity?.id === entity?.id && (
                    <RedMaskIcon entity={entity} entityType={entityType} data={production} />
                  )}
                  <EntityName
                    entityType={entityType || ENTITY_TYPES.PROFILE}
                    entity={entity}
                    isRaw
                    trackingData={trackingData}
                  />
                </Typography>
              </LinkButton>
              {index < displayProfiles.length - 1 && (
                <Typography size={12} className={classes.castInstrumentalist__separator}>
                  ,{' '}
                </Typography>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ProductionSlugDesktop = ({ data: production, openQuickPreview, trackingData, isDayView, entity, entityType }) => {
  const { isCancelled, isArchived, maxDate, minDate } = production || {};
  const { isMobile } = useDeviceTypeLayouts();

  const { producers, directors, conductors, cast, instrumentalists } = useMemo(() => {
    const productionCredits = getProductionCredits(production);
    return {
      producers: productionCredits?.producers,
      directors: productionCredits?.directors?.data || [],
      conductors: productionCredits?.conductors?.data || [],
      cast: productionCredits?.cast || [],
      instrumentalists: productionCredits?.instrumentalists || [],
    };
  }, [production]);

  const producer = producers?.data?.[0];

  const handlePerformanceClick = useCallback(
    e => {
      e.stopPropagation();
      openQuickPreview(e, {
        productionId: production?.id,
      });
    },
    [openQuickPreview, production],
  );

  if (isMobile) {
    return (
      <ImpressionTracker
        as="li"
        className={classnames(classes.productionSlugMobile, 'h-event')}
        itemScope
        itemType="http://schema.org/Event"
      >
        <article
          className={classnames(classes.productionSlugMobile__container, {
            [classes.productionCancelled]: isCancelled,
          })}
          onClick={handlePerformanceClick}
        >
          <div className={classes.productionSlugMobile__container_main}>
            <div className={classes.mobileBody}>
              <div className={classes.mobileColumn1}>
                <div className={classes.mobileDates}>
                  <DateRange production={production} isDayView={isDayView} />
                  <PerformanceDates data={production} />
                </div>
                <div className={classes.mobileVenuesListing}>
                  <VenuesListing production={production} />
                </div>
              </div>
              <div className={classes.mobileColumn2}>
                <div className={classes.mobileColumn2}>
                  <div className={classes.mobileWorks}>
                    <MusicalWorks
                      production={production}
                      isCancelled={isCancelled}
                      isArchived={isArchived}
                      trackingData={trackingData}
                    />
                  </div>
                  <div>
                    {producer && (
                      <Producer
                        producer={producer}
                        isCancelled={isCancelled}
                        isArchived={isArchived}
                        trackingData={trackingData}
                        minDate={createDate(minDate)}
                        maxDate={createDate(maxDate)}
                      />
                    )}
                  </div>
                  <div>
                    {conductors?.length > 0 && (
                      <ConductorDirector
                        title="C:"
                        crew={conductors}
                        isCancelled={isCancelled}
                        isArchived={isArchived}
                        trackingData={trackingData}
                      />
                    )}
                    {directors?.length > 0 && (
                      <ConductorDirector
                        title="D:"
                        crew={directors}
                        isCancelled={isCancelled}
                        isArchived={isArchived}
                        trackingData={trackingData}
                      />
                    )}
                  </div>
                  <div>
                    {(cast?.length > 0 || instrumentalists?.length > 0) && (
                      <CastInstrumentalist
                        cast={cast}
                        instrumentalists={instrumentalists}
                        isCancelled={isCancelled}
                        isArchived={isArchived}
                        trackingData={trackingData}
                        production={production}
                        entity={entity}
                        entityType={entityType}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.mobileFooter}>
              <div className={classes.mobileFooter__tags}>
                <WorkTag production={production} className={classes.workTag} />
                <StageTag production={production} />
              </div>
              <div>
                <Actions production={production} />
              </div>
            </div>
          </div>
        </article>
      </ImpressionTracker>
    );
  }

  return (
    <ImpressionTracker
      as="li"
      className={classnames(classes.productionSlugDesktop, 'h-event')}
      itemScope
      itemType="http://schema.org/Event"
    >
      <article
        className={classnames(classes.productionSlugDesktop__container, {
          [classes.productionCancelled]: isCancelled,
        })}
        onClick={handlePerformanceClick}
      >
        <div className={classnames('p-category', classes.column1)}>
          <div className={classes.workStageTagBlock}>
            <span className={classes.expandIconItem}>
              <SpriteIcon icon="expand_more" size={14} />
            </span>
            <div className={classes.mainTags}>
              <WorkTag production={production} className={classes.workTag} />
              <StageTag production={production} />
            </div>
          </div>
          <div className={classes.column1_otherTags}>
            <ProductionTags production={production} />
          </div>
          <div className={classes.column1_actions}>
            <Actions production={production} />
          </div>
        </div>
        <div className={classes.column2}>
          <div className={classes.column2_tags}>
            <ProductionTags production={production} />
          </div>
          <div className={classes.column2_datesAndVenues}>
            <DateRange production={production} isDayView={isDayView} />
            <PerformanceDates data={production} />
            <VenuesListing production={production} />
          </div>
        </div>
        <div className={classes.column3}>
          <div className={classes.column3_dates}>
            <DateRange production={production} isDayView={isDayView} />
            <PerformanceDates data={production} />
          </div>
          <div className={classes.column3_venues}>
            <VenuesListing production={production} />
          </div>
          <div className={classes.column3_worksProducer}>
            <MusicalWorks
              production={production}
              isCancelled={isCancelled}
              isArchived={isArchived}
              trackingData={trackingData}
            />
            {producer && (
              <Producer
                producer={producer}
                isCancelled={isCancelled}
                isArchived={isArchived}
                trackingData={trackingData}
                minDate={createDate(minDate)}
                maxDate={createDate(maxDate)}
              />
            )}
          </div>
        </div>
        <div className={classes.column4}>
          <div className={classes.column4_venuesListing}>
            <VenuesListing production={production} />
          </div>
          <div className={classes.column4_crewCastInst}>
            {conductors?.length > 0 && (
              <ConductorDirector
                title="C:"
                crew={conductors}
                isCancelled={isCancelled}
                isArchived={isArchived}
                trackingData={trackingData}
              />
            )}
            {directors?.length > 0 && (
              <ConductorDirector
                title="D:"
                crew={directors}
                isCancelled={isCancelled}
                isArchived={isArchived}
                trackingData={trackingData}
              />
            )}
            {(cast?.length > 0 || instrumentalists?.length > 0) && (
              <CastInstrumentalist
                cast={cast}
                instrumentalists={instrumentalists}
                isCancelled={isCancelled}
                isArchived={isArchived}
                trackingData={trackingData}
                production={production}
                entity={entity}
                entityType={entityType}
              />
            )}
          </div>
        </div>
        <div className={classes.column5}>
          <div className={classes.column5_works}>
            <MusicalWorks
              production={production}
              isCancelled={isCancelled}
              isArchived={isArchived}
              trackingData={trackingData}
            />
          </div>
          <div className={classes.column5_actions}>
            <Actions production={production} />
          </div>
        </div>
        <div className={classnames(classes.column6)}>
          {producer && (
            <Producer
              producer={producer}
              isCancelled={isCancelled}
              isArchived={isArchived}
              trackingData={trackingData}
              minDate={createDate(minDate)}
              maxDate={createDate(maxDate)}
            />
          )}
        </div>
        <div className={classnames(classes.column7)}>
          {conductors?.length > 0 && (
            <ConductorDirector
              title="C:"
              crew={conductors}
              isCancelled={isCancelled}
              isArchived={isArchived}
              trackingData={trackingData}
            />
          )}
          {directors?.length > 0 && (
            <ConductorDirector
              title="D:"
              crew={directors}
              isCancelled={isCancelled}
              isArchived={isArchived}
              trackingData={trackingData}
            />
          )}
        </div>
        <div className={classes.column8}>
          {(cast?.length > 0 || instrumentalists?.length > 0) && (
            <CastInstrumentalist
              cast={cast}
              instrumentalists={instrumentalists}
              isCancelled={isCancelled}
              isArchived={isArchived}
              trackingData={trackingData}
              production={production}
              entity={entity}
              entityType={entityType}
            />
          )}
        </div>
        <div className={classes.column9}>
          <Actions production={production} />
        </div>
      </article>
    </ImpressionTracker>
  );
};

export default ProductionSlugDesktop;
