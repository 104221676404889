import React, { useCallback } from 'react';
import { useTranslation } from 'src/i18n';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import { PrimaryButton, SecondaryButton } from 'components/uiLibrary/LinkButton';
import ShareEntity from 'components/Globals/ShareEntity';
import { COMPONENTS, GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';

import useTracking from 'components/Globals/Analytics';
import { currencySymbols } from 'containers/Payment/constants';

import { createDate } from 'utils/date';
import { trackClickTickets } from 'utils/tracking';

import { TP, PERFORMANCE_DATE_MODE_TYPES } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';

import classes from './Actions.module.scss';

const PrimaryActions = ({ production }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();

  const { maxDate, tickets, boxOfficeWebsite, performances } = production || {};
  const ticketWebsite = tickets?.[0]?.website || boxOfficeWebsite;
  const currencySymbol = currencySymbols[tickets?.[0]?.currency?.currency] || {};
  const hasTickets =
    ticketWebsite && (createDate().isSame(maxDate, 'day') || createDate(maxDate)?.isAfter(createDate(), 'day'));
  const hasUpcomingPerformances = createDate(maxDate)?.isAfter(createDate(), 'day');
  const hasUpcomingDateRange = maxDate && !performances?.length;

  const hasWatchOptions = performances?.some(performance =>
    [PERFORMANCE_DATE_MODE_TYPES.FULL_VIDEO, PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM].includes(performance?.mode),
  );

  const ticketPrice =
    tickets?.[0]?.minTicketPrice && tickets?.[0]?.maxTicketPrice
      ? `${Number(tickets?.[0]?.minTicketPrice)} - ${Number(tickets?.[0]?.maxTicketPrice)}`
      : null;

  const trackTickets = useCallback(() => {
    track.click(
      trackClickTickets({ 'Click URL': ticketWebsite, production_id: production?.id }),
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  }, [track, ticketWebsite, production]);

  const handleSaveProduction = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    // TODO: FB-000000 Implement save production action
  }, []);

  if (production?.isCancelled || production?.isArchived) {
    return null;
  }

  /* NOTE: Version One & Two - Upcoming performances with ticket website link */
  if (hasUpcomingPerformances && hasTickets) {
    return (
      <div className={classes.actionsWrapper}>
        <PrimaryButton
          styles={{ root: classes.actionBtn }}
          shape="rectangle"
          href={ticketWebsite}
          isLink
          external
          stopPropagation
          onClick={trackTickets}
        >
          {t(`${TP}.FN_TICKETS`)}
          {ticketPrice && (
            <Typography size={11} color="secondary" weight="medium" className={classes.priceRange}>
              | {currencySymbol} {ticketPrice}
            </Typography>
          )}
        </PrimaryButton>
      </div>
    );
  }

  /* NOTE: Version Three - Upcoming performances but no ticket website link */
  if (hasUpcomingPerformances && !hasTickets && !hasWatchOptions) {
    return (
      <div className={classes.actionsWrapper}>
        <SecondaryButton
          styles={{ root: classes.actionBtnSecondary }}
          shape="rectangle"
          onClick={handleSaveProduction}
          stopPropagation
        >
          {t(`${TP}.ALERT_ME`)}
        </SecondaryButton>
      </div>
    );
  }

  /* NOTE: Version Four - Upcoming performances with links for ticket purchase, live stream, or VOD */
  if (hasUpcomingPerformances && hasWatchOptions) {
    return (
      <div className={classes.actionsWrapper}>
        <PrimaryButton styles={{ root: classes.actionBtn }} shape="rectangle" stopPropagation>
          {t(`${TP}.FN_WATCH_OPTIONS`)}
        </PrimaryButton>
      </div>
    );
  }

  /* NOTE: Version Five - No specific upcoming performance dates but an upcoming date range */
  if (hasUpcomingDateRange && !hasWatchOptions) {
    return (
      <div className={classes.actionsWrapper}>
        <SecondaryButton
          styles={{ root: classes.actionBtnSecondary }}
          shape="rectangle"
          onClick={handleSaveProduction}
          stopPropagation
        >
          {t(`${TP}.ALERT_ME`)}
        </SecondaryButton>
      </div>
    );
  }

  /* NOTE: Version Six - No upcoming performances but links available for live stream or VOD */
  if (!hasUpcomingPerformances && hasWatchOptions) {
    return (
      <div className={classes.actionsWrapper}>
        <PrimaryButton styles={{ root: classes.actionBtn }} shape="rectangle" stopPropagation>
          {t(`${TP}.FN_WATCH_OPTIONS`)}
        </PrimaryButton>
      </div>
    );
  }

  /* NOTE: Version Seven - No upcoming performances and no links to live stream or VOD */
  if (!hasUpcomingPerformances && !hasWatchOptions) {
    return (
      <div className={classes.actionsWrapper}>
        <SecondaryButton
          styles={{ root: classes.actionBtnSecondary }}
          shape="rectangle"
          onClick={handleSaveProduction}
          stopPropagation
        >
          {t(`${TP}.WATCH_ONLINE_ALERT`)}
        </SecondaryButton>
      </div>
    );
  }

  return null;
};

const Actions = ({ production, trackingData }) => (
  <div className={classes.actions}>
    <div className={classes.actions__primary}>
      <PrimaryActions production={production} />
    </div>
    <div className={classes.actions__secondary}>
      <div className={classes.actions__secondary_bookmark}>
        <SpriteIcon icon="bookmark_add" size={20} className={classes.icon} />
      </div>
      <div className={classes.actions__secondary_share}>
        <ShareEntity
          entityType={ENTITY_TYPES.PRODUCTION}
          entity={production}
          className={classes.share}
          trackingData={{ ...trackingData, component: COMPONENTS.SHARE_CTA }}
          showLabelText={false}
        />
      </div>
    </div>
  </div>
);

export default Actions;
