import React from 'react';
import { useTranslation } from 'src/i18n';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import EntityName from 'components/Globals/EntityName';
import { getCityCountry } from 'utils/globals';
import { TP } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';

import classes from './VenuesListing.module.scss';

const VenuesListing = ({ production }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { venues } = production || {};
  const firstVenue = venues?.[0];
  const cityCountry = getCityCountry(firstVenue);

  const [city, country] = cityCountry?.split(', ') || [];

  if (!firstVenue?.name) {
    return (
      <Typography size={12} weight="medium">
        {t(`${TP}.FN_VENUE_NOT_ANNOUNCED`)}
      </Typography>
    );
  }

  return (
    <div className={classes.venue}>
      <div className={classes.venue__location}>
        <Typography size={12} color="primary">
          {city && country ? `${city}, ` : city || ''}
        </Typography>
        {country && (
          <Typography size={12} className={classes.country}>
            {country}
          </Typography>
        )}
      </div>
      <div className={classes.venue__name}>
        <SpriteIcon icon="location" size={10} className={classes.locationIcon} />
        <Typography size={12} truncate>
          <EntityName entity={firstVenue} entityType={ENTITY_TYPES.ORGANIZATION} isRaw />
        </Typography>
      </div>
      {venues?.length > 1 && (
        <Typography variant="p" size={12} color="secondary" className={classes.moreVenues}>
          {`+${venues?.length - 1} ${t(`${TP}.MORE`)} ${t(`${TP}.m_VENUES`)}`}
        </Typography>
      )}
    </div>
  );
};

export default VenuesListing;
