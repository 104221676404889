import React, { Fragment, useCallback, useMemo, useState } from 'react';
import Head from 'next/head';
import classnames from 'classnames';
import dynamic from 'next/dynamic';

import InfiniteListPage, { PageLoading } from 'components/Globals/Layout/InfiniteListPage';
import NoResult from 'components/Globals/NoResult';
import ProductionSlugDesktop from 'components/Productions/Display/Slugs/ProductionSlugDesktop';
import useQuickView, { QUICK_VIEW_TYPES } from 'components/Globals/Layout/QuickView';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';
import CalendarProductionListing, { CalendarViewSlug } from 'components/Productions/Display/CalendarProductionListing';
import FilterFacets, { FILTER_LAYOUT } from 'components/Filters/Display/FilterFacets';
import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';

import queries from 'containers/Productions/queries';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';
import ProductionSEO from 'utils/seo/production';
import { groupProductionsPerformances, getDatesRangeDaysDifference } from 'utils/productions';
import { Trans, useTranslation } from 'src/i18n';
import { TP, PRODUCTION_LISTING_VIEW_TYPES, SORT_OPTION_TYPES } from 'constants/index';

import AppliedFilters from 'components/Filters/Display/AppliedFilters';
import classes from './ProductionListing.module.scss';

const ProductionMediaCard = dynamic(import('components/Productions/Display/Slugs/ProductionMediaCard'));
const ProductionMobileSlug = dynamic(import('components/Productions/Display/Slugs/ProductionMobileSlug'));

const VIEW_TYPES_SLUG_MAP = {
  [PRODUCTION_LISTING_VIEW_TYPES.SEASON]: ProductionSlugDesktop,
  [PRODUCTION_LISTING_VIEW_TYPES.PERFORMANCE_VIEW]: ProductionMobileSlug,
  [PRODUCTION_LISTING_VIEW_TYPES.CALENDAR_VIEW]: CalendarViewSlug,
  [PRODUCTION_LISTING_VIEW_TYPES.MEDIA]: ProductionMediaCard,
};

const JsonLD = ({ events }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  const itemListSchema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: events.map((production, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: ProductionSEO.getEventJsonLd({ t, entity: production }),
    })),
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(itemListSchema),
        }}
      />
    </Head>
  );
};

const RenderSlug = ({ viewType, pages, trackingData, openQuickPreview, onPlayTrailer, isDayView }) => {
  const { entity, entityType } = usePageContext();

  const groupedProductions = useMemo(() => groupProductionsPerformances(pages, entity), [pages, entity]);

  const dataSource = isDayView ? groupedProductions : pages?.flatMap(({ data }) => data) || [];
  const SlugComponent = VIEW_TYPES_SLUG_MAP[viewType];

  if (!dataSource.length) return null;

  return (
    <>
      <JsonLD events={dataSource} />
      {dataSource.map((production, index) => (
        <SlugComponent
          key={`${production.id || production?.id}-${index}`}
          data={production}
          entity={entity}
          entityType={entityType}
          trackingData={trackingData}
          openQuickPreview={openQuickPreview}
          onPlayTrailer={onPlayTrailer}
          isDayView={isDayView}
        />
      ))}
    </>
  );
};

const ListWrapper = ({
  viewType,
  entity,
  query,
  infinite = true,
  trackingData,
  onPlayTrailer,
  mediaSlugInCarousel,
  setTotalPerformancesCount,
  isDayView,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { showQuickView } = useQuickView();

  const openQuickPreview = useCallback(
    (e, { productionId, scrollToSectionId }) => {
      showQuickView({
        type: QUICK_VIEW_TYPES.PRODUCTION,
        data: {
          entityId: productionId,
          quickViewContextId: entity?.id,
          scrollToSectionId,
        },
      });

      // NOTE: showQuickView is not required as depedency for this hook
    },
    [entity?.id, viewType],
  );

  // NOTE: HorizontalScroller is needed in LS and VOD listing
  const Component = mediaSlugInCarousel ? HorizontalScroller : Fragment;
  return (
    <InfiniteListPage
      query={query}
      disabled={!infinite || mediaSlugInCarousel}
      disableQueryUpdate
      updateAppliedFilters={infinite}
      onPageLoad={data => setTotalPerformancesCount(data?.actualTotal)}
    >
      {({ pages, count, isLoading }) => (
        <>
          {!isLoading && count === 0 ? (
            <NoResult
              title={t(`${TP}.FN_ARTIST_PRO_SEARCH_NO_RESULT`)}
              subtitle={t(`${TP}.FN_ARTIST_PRO_SEARCH_NO_RESULT_SUBTITLE`)}
            />
          ) : (
            <Component {...(mediaSlugInCarousel && { outset: true })}>
              <ul
                className={classnames(classes.listWrapper, {
                  [classes.listWrapper__horizontal]: !!mediaSlugInCarousel,
                  [classes.listWrapper__media]: PRODUCTION_LISTING_VIEW_TYPES.MEDIA === viewType,
                  [classes.listWrapper__listingPage]: !entity?.id,
                })}
              >
                {viewType === PRODUCTION_LISTING_VIEW_TYPES.CALENDAR_VIEW ? (
                  <CalendarProductionListing
                    rows={
                      <RenderSlug
                        pages={pages}
                        viewType={viewType}
                        trackingData={trackingData}
                        openQuickPreview={openQuickPreview}
                        onPlayTrailer={onPlayTrailer}
                      />
                    }
                  />
                ) : (
                  <RenderSlug
                    pages={pages}
                    viewType={viewType}
                    trackingData={trackingData}
                    openQuickPreview={openQuickPreview}
                    onPlayTrailer={onPlayTrailer}
                    isDayView={isDayView}
                  />
                )}
              </ul>
            </Component>
          )}
          <PageLoading defaultLoader />
        </>
      )}
    </InfiniteListPage>
  );
};

const Actions = ({ viewType, onViewTypeChange, totalPerformancesCount }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isMobile } = useDeviceTypeLayouts();

  if (isMobile) {
    return null;
  }

  return (
    <div className={classes.actions}>
      <div className={classes.actions__primary}>
        <div className={classes.actions__viewTypeContainer}>
          <LinkButton
            variant="grey"
            styles={{
              root: classnames(classes.viewType, {
                [classes.viewType__active]: isMobile
                  ? viewType === PRODUCTION_LISTING_VIEW_TYPES.DAY
                  : viewType === PRODUCTION_LISTING_VIEW_TYPES.SEASON,
              }),
            }}
            size="medium"
            onClick={() =>
              onViewTypeChange(isMobile ? PRODUCTION_LISTING_VIEW_TYPES.DAY : PRODUCTION_LISTING_VIEW_TYPES.SEASON)
            }
            leftIcon={<SpriteIcon icon={isMobile ? 'calendar' : 'list'} size={14} />}
            disableHover
            preventDefault
            stopPropagation
          >
            {isMobile ? t(`${TP}.FN_DAY`) : t(`${TP}.FN_LIST`)}
          </LinkButton>
          <LinkButton
            variant="grey"
            styles={{
              root: classnames(classes.viewType, {
                [classes.viewType__active]: isMobile
                  ? viewType === PRODUCTION_LISTING_VIEW_TYPES.SEASON
                  : viewType === PRODUCTION_LISTING_VIEW_TYPES.CALENDAR_VIEW,
              }),
            }}
            size="medium"
            onClick={() =>
              onViewTypeChange(
                isMobile ? PRODUCTION_LISTING_VIEW_TYPES.SEASON : PRODUCTION_LISTING_VIEW_TYPES.CALENDAR_VIEW,
              )
            }
            leftIcon={<SpriteIcon icon={isMobile ? 'list' : 'calendar'} size={14} />}
            disableHover
            preventDefault
            stopPropagation
          >
            {isMobile ? t(`${TP}.FN_SEASON`) : t(`${TP}.FN_CALENDAR`)}
          </LinkButton>
        </div>
        {totalPerformancesCount > 0 && (
          <Typography size="12" variant="h2">
            <Trans
              ns="NS_APP_GLOBALS"
              i18nKey={`${TP}.FN_SHOWING_X_COUNT`}
              components={{
                msg: totalPerformancesCount?.toString() || 0,
                span: <Typography size="12" weight="medium" className={classes.productionCount} />,
              }}
            />
            {t(`PERFORMANCES`)}
          </Typography>
        )}
      </div>
    </div>
  );
};

const ProductionListing = ({
  filters,
  limit = 10,
  viewMode,
  infinite = true,
  styles,
  trackingData,
  customQueryFn,
  onPlayTrailer,
  mediaSlugInCarousel = false,
  hideFilters = false,
  upcoming = false,
  hideActions = false,
}) => {
  const { entityType, entity } = usePageContext();
  const [totalPerformancesCount, setTotalPerformancesCount] = useState();
  const [viewType, setViewType] = useState(() => {
    if (viewMode) {
      return viewMode;
    }

    return PRODUCTION_LISTING_VIEW_TYPES.SEASON;
  });

  const { obRouteContext } = useAppContext();
  const { filterParams = {} } = obRouteContext;
  const { date_from, date_to } = filterParams;

  const isDayView = useMemo(() => getDatesRangeDaysDifference(date_from, date_to) < 31 || upcoming, [
    date_from,
    date_to,
  ]);

  const query = useMemo(() => {
    const queryFilters = {
      ...(entityType && { entityType }),
      ...(entity?.id && { entityId: entity?.id }),
      filters: {
        sort: SORT_OPTION_TYPES.YEAR_DESC.value,
        ...filterParams,
        ...filters,
      },
      limit,
    };

    if (customQueryFn) {
      return customQueryFn(queryFilters);
    }

    if (
      [PRODUCTION_LISTING_VIEW_TYPES.CALENDAR_VIEW, PRODUCTION_LISTING_VIEW_TYPES.MEDIA].includes(viewType) ||
      !isDayView
    ) {
      return queries.getProductions(queryFilters);
    }

    return queries.getPerformances(queryFilters);
  }, [entityType, entity?.id, filterParams, filters, limit, customQueryFn, viewType, isDayView]);

  return (
    <div
      className={classnames(classes.root, {
        [styles?.root]: !!styles?.root,
      })}
    >
      {viewMode !== PRODUCTION_LISTING_VIEW_TYPES.MEDIA && !hideActions && (
        <Actions
          totalPerformancesCount={totalPerformancesCount}
          viewType={viewType}
          onViewTypeChange={type => setViewType(type)}
          hideFilters={hideFilters}
        />
      )}
      {viewType !== PRODUCTION_LISTING_VIEW_TYPES.CALENDAR_VIEW && !hideFilters && (
        <div className={!entity?.id ? classes.filtersFacetsWrapper : ''}>
          <FilterFacets
            viewType={viewType}
            layout={FILTER_LAYOUT.HORIZONTAL}
            styles={{
              root: classes.filterContainerDetailed,
              filterGroupBox: classes.filterGroupBox,
            }}
            ignoreSmallScreenGroup
            truncateInOneLine
            scrollable
          />
        </div>
      )}
      <div className={!entity?.id ? classes.appliedFiltersWrapper : ''}>
        <AppliedFilters className={classes.appliedFilters} isVisibleAlways />
      </div>
      <ListWrapper
        viewType={viewType}
        entityType={entityType}
        entity={entity}
        query={query}
        infinite={infinite}
        trackingData={trackingData}
        mediaSlugInCarousel={mediaSlugInCarousel}
        onPlayTrailer={onPlayTrailer}
        setTotalPerformancesCount={setTotalPerformancesCount}
        isDayView={isDayView}
      />
    </div>
  );
};

export default ProductionListing;
