import React, { useMemo } from 'react';
import { object, string, array, date } from 'yup';

import {
  SocialProfileJsonLd,
  BreadcrumbJsonLd,
  LocalBusinessJsonLd,
  WebPageJsonLd,
  VideoJsonLd,
  EventJsonLd,
} from 'next-seo';

import { useJsonLd } from 'utils/seo/index';
import { updateSubDomainDataToImages } from 'utils/globals/app';
import { JSON_LD_TYPES } from 'constants/index';

const SCHEMAS = {
  [JSON_LD_TYPES.SOCIAL_PROFILE]: object().shape({
    type: string().required(),
    mainEntity: object()
      .shape({
        '@type': string().required(),
        name: string().required(),
      })
      .required(),
  }),
  [JSON_LD_TYPES.BREADCRUMB]: object().shape({
    itemListElements: array().min(1),
  }),
  [JSON_LD_TYPES.WEBPAGE]: object().shape({
    type: string().required(),
    name: string().required(),
    mainEntity: object()
      .shape({
        '@type': string().required(),
        startDate: string().required(),
        location: object().shape({
          '@type': string().required(),
          name: string().required(),
          address: object().required(),
        }),
      })
      .required(),
  }),
  [JSON_LD_TYPES.LIVESTREAM]: object().shape({
    name: string().required(),
    description: string().required(),
    thumbnailUrls: array().required(),
    uploadDate: date().required(),
  }),
  [JSON_LD_TYPES.VOD]: object().shape({
    name: string().required(),
    description: string().required(),
    thumbnailUrls: array().required(),
    uploadDate: date().required(),
  }),
  [JSON_LD_TYPES.VIDEO]: object().shape({ name: string().required() }),
};

const isValidJsonLdSchema = (type, data) => {
  const schema = SCHEMAS[type];

  if (!schema) {
    return !!data;
  }

  return schema.isValidSync(data);
};

const JsonLD = ({ type, entityType, entity, activeTab, data }) => {
  const jsonLdValue = useJsonLd({ type, entityType, entity, activeTab, data });
  const jsonLdData = useMemo(() => {
    const { mainEntity, images, thumbnailUrls, ...restJsonLdData } = jsonLdValue || {};
    const updateJsonLD = {
      ...restJsonLdData,
      ...(images?.length > 0 && { images: images.map(path => updateSubDomainDataToImages(path)) }),
      ...(thumbnailUrls?.length > 0 && { thumbnailUrls: thumbnailUrls.map(path => updateSubDomainDataToImages(path)) }),
    };

    if (!mainEntity) {
      return updateJsonLD;
    }

    const { image, ...restMainEntity } = mainEntity || {};

    return {
      ...updateJsonLD,
      mainEntity: {
        ...restMainEntity,
        ...(image && { image: updateSubDomainDataToImages(image) }),
      },
    };
  }, [jsonLdValue]);

  const isValidJsonLD = useMemo(() => isValidJsonLdSchema(type, jsonLdData), [type, jsonLdData]);
  if (!isValidJsonLD) {
    return null;
  }

  switch (type) {
    case JSON_LD_TYPES.SOCIAL_PROFILE: {
      return <SocialProfileJsonLd {...jsonLdData} />;
    }
    case JSON_LD_TYPES.LOCAL_BUSINESS: {
      return <LocalBusinessJsonLd {...jsonLdData} />;
    }
    case JSON_LD_TYPES.WEBPAGE: {
      return <WebPageJsonLd {...jsonLdData} />;
    }
    case JSON_LD_TYPES.EVENT: {
      return <EventJsonLd {...jsonLdData} />;
    }
    case JSON_LD_TYPES.VIDEO:
    case JSON_LD_TYPES.VOD:
    case JSON_LD_TYPES.LIVESTREAM: {
      return <VideoJsonLd {...jsonLdData} />;
    }
    case JSON_LD_TYPES.BREADCRUMB: {
      return <BreadcrumbJsonLd {...jsonLdData} />;
    }
    default: {
      return null;
    }
  }
};

export default JsonLD;
