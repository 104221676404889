import React, { useMemo } from 'react';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import usePageContext from 'utils/hooks/usePageContext';
import { formateYearMonthGroupDates, filterFuturePerformances } from 'utils/productions';
import { createDate } from 'utils/date';
import { DATE_FORMATS } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';

import classes from './PerformanceDates.module.scss';

const PerformanceDates = ({ data }) => {
  const { navigate } = usePageContext();
  const { performances } = data || {};

  // Get filtered future dates excluding the first date in the list
  const upcomingPerformanceDates = useMemo(() => {
    if (!data?.upcomingDates?.length) {
      return [];
    }

    const futureDates = filterFuturePerformances(data?.upcomingDates);
    return futureDates.filter(date => date !== data?.dates?.[0]?.date);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Determine which dates to use (either production performances or just performance dates)
  const displayedDates = performances?.length > 0 ? performances : upcomingPerformanceDates;

  // Group dates by year and month
  const groupedPerformanceDates = useMemo(() => formateYearMonthGroupDates(displayedDates), [displayedDates]);

  const linkProps = useMemo(() => navigate.getLinkProps({ entity: data, entityType: ENTITY_TYPES.PRODUCTION }), [
    data,
    navigate,
  ]);

  return (
    <div className={classes.performanceDates}>
      {Object.entries(groupedPerformanceDates).map(([year, months]) => (
        <div key={year} className={classes.groupedDates}>
          {Object.entries(months).map(([month, days]) => (
            <div key={month} className={classes.groupedDates__month}>
              <Typography size={12} color="secondary" className={classes.groupedDates__month_name} italic>
                {month}
              </Typography>
              <Typography truncate className={classes.groupedDates__month_dates}>
                <ol className={classes.datesList}>
                  {days
                    .sort((a, b) => a.day - b.day)
                    .map(({ fullDate }, index) => {
                      const performanceLinkProps = {
                        ...linkProps?.getSubPath({
                          path: createDate(fullDate)
                            .locale('en')
                            .format(DATE_FORMATS.URL_FULL_DATE),
                        }),
                      };

                      return (
                        <li
                          key={fullDate}
                          className="p-performance"
                          itemProp="startDate"
                          dateTime={createDate(fullDate).format(DATE_FORMATS.FULL_DATE)}
                        >
                          <LinkButton variant="native" isLink {...performanceLinkProps} preventDefault>
                            <Typography size={12} className={classes.date} color="secondary" italic>
                              <time dateTime={createDate(fullDate).format(DATE_FORMATS.FULL_DATE)}>
                                {createDate(fullDate).format(DATE_FORMATS.DATE)}
                              </time>
                              {index < days.length - 1 && ','}
                            </Typography>
                          </LinkButton>
                        </li>
                      );
                    })}
                </ol>
              </Typography>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PerformanceDates;
