import React from 'react';
import classnames from 'classnames';
import Typography from 'components/uiLibrary/Typography';
import { createDate } from 'utils/date';
import { isWeekend } from 'utils/calendar';
import { CALENDAR_VIEW_TYPES } from 'constants/calendar';

import { DATE_FORMATS } from 'constants/index';
import classes from './DayCell.module.scss';

const DayCell = ({ viewType, cellDate, day, performedDates }) => {
  const isWeekendDay = isWeekend(cellDate);
  const matchingDate = performedDates.find(d => cellDate.isSame(createDate(d.startDate)));

  const isPast = matchingDate?.startDate ? createDate(matchingDate.startDate) < createDate() : false;
  const fifteenDay = viewType === CALENDAR_VIEW_TYPES.FIFTEEN_DAYS;
  const dayName =
    viewType === CALENDAR_VIEW_TYPES.FIFTEEN_DAYS
      ? cellDate.format(DATE_FORMATS.SHORT_DAY)
      : cellDate.format(DATE_FORMATS.FULL_DAY).charAt(0);

  return (
    <Typography
      size={10}
      variant="div"
      className={classnames(classes.monthDay, {
        [classes.monthDayWeekEnd]: isWeekendDay,
        [classes.monthDayFuture]: !fifteenDay && matchingDate?.startDate,
        [classes.monthDayPast]: !fifteenDay && isPast,
        [classes.fifteenDay]: fifteenDay,
        [classes.fifteenDayPast]: fifteenDay && isPast,
      })}
    >
      {matchingDate?.startDate && (
        <>
          <Typography size={10} weight="medium">
            {day < 10 ? String(day).padStart(2, '0') : String(day)}
          </Typography>
          <Typography size={10}>{dayName}</Typography>
        </>
      )}
    </Typography>
  );
};

export default DayCell;
