import React from 'react';

import { createDate } from 'utils/date';
import { CALENDAR_VIEW_TYPES } from 'constants/calendar';
import DayCell from './DayCell';
import MonthCell from './MonthCell';
import classes from './Cells.module.scss';

const Cells = ({ calendarViewType, startDate, production, months, year }) => {
  if ([CALENDAR_VIEW_TYPES.MONTH, CALENDAR_VIEW_TYPES.FIFTEEN_DAYS].includes(calendarViewType)) {
    const dates = production?.performances?.map(d => ({ startDate: d.startDate, id: d.id }));

    return (
      <>
        {Array.from({ length: calendarViewType === CALENDAR_VIEW_TYPES.FIFTEEN_DAYS ? 15 : 30 }).map((_, index) => {
          const currentDate = startDate.add(index, 'day');
          const cellDate = createDate(`${currentDate.year()}-${currentDate.month() + 1}-${currentDate.date()}`);
          return (
            <td key={index}>
              <DayCell
                cellDate={cellDate}
                day={cellDate.date()}
                performedDates={dates}
                productionId={production?.id}
                viewType={calendarViewType}
                dayIndex={index}
              />
            </td>
          );
        })}
      </>
    );
  }

  return (
    <>
      {months.map((_, i) => {
        const dates = production?.performances
          ?.map(d => ({ startDate: d.startDate, id: d.id }))
          ?.sort((a, b) => createDate(a.startDate)?.diff(createDate(b.startDate, 'day')));

        return (
          <td key={i} className={classes.months}>
            <MonthCell
              productionId={production?.id}
              month={calendarViewType === CALENDAR_VIEW_TYPES.YEAR ? i : (startDate.month() + i) % 12}
              year={calendarViewType === CALENDAR_VIEW_TYPES.YEAR ? year : startDate.year()}
              viewType={calendarViewType}
              performedDates={dates}
            />
          </td>
        );
      })}
    </>
  );
};

export default Cells;
